import { AuditFieldType } from "api/models/audit/auditApi";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { commonConstants } from "lynxConstants";
import { formatDurationToString } from "helpers/formatDurationToString";
import { formatTemperature } from "helpers/formatTemperatureRange";
import { isEmptyOrWhitespace } from "helpers/stringHelpers";
import {
    convertDateToSelectedTimezone,
    dateInLocalTimezone,
    dateInUTC,
    getFormatedTimezoneOffset,
} from "helpers/timezoneHelper";
import { JSONObject } from "helpers/typeHelpers";
import { isNumber } from "lodash";

export function AuditPayloadParser(value: JSONObject, valueType: AuditFieldType, timezone?: string): React.ReactNode {
    if (
        value === null ||
        (valueType === AuditFieldType.Array && (value as []).length === 0) ||
        (valueType === AuditFieldType.Number && !isNumber(value)) ||
        ((valueType === AuditFieldType.String || valueType === AuditFieldType.User) &&
            !isEmptyOrWhitespace(value as string))
    ) {
        return commonConstants.emptyValue;
    }

    switch (valueType) {
        case AuditFieldType.String:
        case AuditFieldType.User:
        case AuditFieldType.Number:
            return value;

        case AuditFieldType.Duration:
            return formatDurationToString(value as number, true);

        case AuditFieldType.Date:
            // TODO: Figure out should dates be impacted by timezone?
            return dateInUTC(value as any).format(commonConstants.shortDateFormat);

        case AuditFieldType.DateTime:
            return `${(timezone
                ? convertDateToSelectedTimezone(value as any, timezone)
                : dateInLocalTimezone(value as any)
            ).format(commonConstants.fullDateTimeFormat)}  ${getFormatedTimezoneOffset(value as any, timezone)}`;

        case AuditFieldType.Temperature:
            return formatTemperature(value as number);

        case AuditFieldType.Array:
            return (value as []).map((x) => <LynxTypography key={x}> {x}</LynxTypography>);

        default:
            return commonConstants.emptyValue;
    }
}
