import { LynxButton } from "components/LynxComponents/LynxButton/LynxButton";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { LynxIcon } from "icons/LynxIcon";
import { ReactNode, useEffect, useState } from "react";
import { auditEntryPayloadRendererStyles } from "./AuditEntryPayloadRendererStyles";

export interface AuditEntryPayloadCollapsableContentProps {
    children: ReactNode;
    title: string;
    forceExpand: boolean;
}

export const AuditEntryPayloadCollapsableContent = (props: AuditEntryPayloadCollapsableContentProps) => {
    const classes = auditEntryPayloadRendererStyles();

    const [isExpanded, setIsExpanded] = useState(props.forceExpand);
    const toggleExpanded = () => setIsExpanded((x) => !x);

    useEffect(() => {
        setIsExpanded(props.forceExpand);
    }, [props.forceExpand]);

    return (
        <div className={classes.collapsableContentContainer}>
            <LynxButton variant="icon" className={classes.collapseButton} onClick={toggleExpanded}>
                <LynxIcon name={isExpanded ? "minusSmall" : "plusSmall"} />
            </LynxButton>
            <div className={classes.titleContainer}>
                <LynxTypography variant="h3" color="neutral400">
                    {props.title}
                </LynxTypography>
            </div>
            {isExpanded && (
                <>
                    <div className={classes.verticalLineContainer}>
                        <div data-id="vertical-line"></div>
                    </div>
                    <div className={classes.childrenContainer}>{props.children}</div>
                </>
            )}
        </div>
    );
};

