import { Theme, createStyles, makeStyles } from "@material-ui/core";
import { LynxIcon } from "icons/LynxIcon";
import { AssessmentDecision } from "models/thorEvents/eventModels";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        greenIcon: {
            fill: theme.colors.success.success600,
        },
        yellowIcon: {
            fill: theme.colors.warning.warning600,
        },
        redIcon: {
            fill: theme.colors.critical.critical600,
        },
    })
);

export interface LynxRecommendationIconProps {
    recomendation: AssessmentDecision | null;
}

export const LynxRecommendationIcon = (props: LynxRecommendationIconProps) => {
    const classes = useStyles();

    switch (props.recomendation) {
        case AssessmentDecision.NoImpactOnProductQuality:
            return <LynxIcon name="checkSmall" className={classes.greenIcon} />;
        case AssessmentDecision.ExistingImpactOnProductQuality:
            return <LynxIcon name="crossSmall" className={classes.redIcon} />;
        case AssessmentDecision.AdditionalReviewRequired:
        case null:
            return <LynxIcon name="triangleWarning" className={classes.yellowIcon} />;
    }
};

