import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const lynxSelectStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: theme.colors.neutrals.white,
            border: `1px solid ${theme.colors.neutrals.neutral300}`,
            color: theme.colors.neutrals.neutral600,
            borderRadius: "4px",
            fontSize: "1rem",
            lineHeight: "1.4rem",

            "& > .MuiSelect-root": {
                padding: "0.6875rem 2.1875rem 0.6875rem 0.75rem",
            },

            "& .MuiSelect-icon": {
                top: "initial",
                right: "13.5px",
                fill: theme.colors.neutrals.neutral500,
            },

            "& .MuiCircularProgress-root": {
                width: "1rem",
                height: "1rem",
            },

            // Focus state
            "&.Mui-focused": {
                border: `1px solid ${theme.colors.primary.blue400}`,

                "& > .MuiSelect-select": {
                    borderRadius: "4px",
                    backgroundColor: "white",
                },

                "& .MuiSelect-icon": {
                    fill: theme.colors.primary.blue400,
                },
            },

            // Disabled state
            "&.Mui-disabled": {
                borderColor: theme.colors.neutrals.neutral300,
                backgroundColor: theme.colors.neutrals.neutral200,
                color: theme.colors.neutrals.neutral400,

                "& > .MuiSelect-root": {
                    cursor: "not-allowed",
                },

                "& .MuiSelect-icon": {
                    fill: theme.colors.neutrals.neutral300,
                },
            },

            "& .MuiSelect-menu": {
                minHeight: "initial",
            },
        },

        menu: {
            marginTop: "0.3125rem",
            maxHeight: "20rem",

            "& .MuiMenuItem-root": {
                "&.Mui-selected, &.Mui-selected:hover": {
                    backgroundColor: theme.colors.primary.blue500,
                    color: theme.colors.neutrals.white,
                },

                "&:hover": {
                    backgroundColor: theme.colors.primary.blue75,
                },
            },
        },

        label: {
            marginBottom: "0.25rem",
        },

        placeholderText: {
            color: theme.colors.neutrals.neutral300,
        },
    })
);

