import { Tab, Tabs } from "@material-ui/core";
import clsx from "clsx";
import { HeaderPortal } from "components/ReusableComponents/HeaderPortal";
import { FullScreenLoadingIndicator } from "components/ReusableComponents/LoadingIndicator/FullScreenLoadingIndicator";
import CustomersTab from "components/userManagement/tabs/CustomersTab";
import GroupsTab from "components/userManagement/tabs/GroupsTab";
import UsersTab from "components/userManagement/tabs/UsersTab";
import { anyEquals } from "helpers/permissionHelpers";
import { observer } from "mobx-react";
import { actions } from "models/userManagement/actions";
import { MarvelPermissionStatus } from "models/userManagement/userManagementModels";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { Navigate, Route, Routes, useLocation } from "react-router";
import { Link } from "react-router-dom";
import routes from "routes";
import { useStore } from "store/StoreConfigs";
import { userViewStyles } from "./UserViewStyles";

export default observer(function UserView() {
    const { identityStore, permissionsStore } = useStore();
    const classes = userViewStyles();

    const [stickyEnabled, setStickyEnabled] = useState(false);
    const withSticky = (className: string) => clsx(className, stickyEnabled && "sticky");

    const permissions = identityStore.isSystemSpace
        ? [
              permissionsStore.getPermissionResult(actions.system.customers.view),
              permissionsStore.getPermissionResult(actions.system.customers.manage),
              permissionsStore.getPermissionResult(actions.system.users.view),
              permissionsStore.getPermissionResult(actions.system.users.manage),
              permissionsStore.getPermissionResult(actions.system.groups.view),
              permissionsStore.getPermissionResult(actions.system.groups.manage),
          ]
        : [
              permissionsStore.getPermissionResult(actions.customer.users.view, identityStore.currentCustomer.id),
              permissionsStore.getPermissionResult(actions.customer.users.manage, identityStore.currentCustomer.id),
              permissionsStore.getPermissionResult(actions.customer.groups.view, identityStore.currentCustomer.id),
              permissionsStore.getPermissionResult(actions.customer.groups.manage, identityStore.currentCustomer.id),
          ];

    const allTabs = ["users", "groups", "customers"];
    const allowedTabs: string[] = allTabs.filter((x) =>
        anyEquals(
            permissions,
            permissions.filter((y) => y.key.includes(x)),
            MarvelPermissionStatus.Allow
        )
    );

    const { pathname } = useLocation();
    const tab = pathname.split("/")[2];

    const firstAllowedTab = allowedTabs.find((x, index) => index === 0) || "/";

    if (anyEquals(permissions, "all", ...[MarvelPermissionStatus.Loading, undefined])) {
        return (
            <main>
                <FullScreenLoadingIndicator />
            </main>
        );
    }

    return (
        <main>
            <Helmet>
                <title>User Management</title>
            </Helmet>
            <HeaderPortal callback={setStickyEnabled} stickyEnabled={stickyEnabled}>
                <Tabs value={tab} className={withSticky(classes.tabList)}>
                    {allowedTabs.includes("users") ? (
                        <Tab label="Users" value="users" component={Link} to="users" className={classes.tab} />
                    ) : null}
                    {allowedTabs.includes("groups") ? (
                        <Tab label="User Groups" value="groups" component={Link} to="groups" className={classes.tab} />
                    ) : null}
                    {allowedTabs.includes("customers") ? (
                        <Tab
                            value="customers"
                            label="Customers"
                            component={Link}
                            to="customers"
                            className={classes.tab}
                        />
                    ) : null}
                </Tabs>
            </HeaderPortal>

            <div className={classes.tabContainer}>
                <Routes>
                    {allowedTabs.includes("users") ? <Route path="users" element={<UsersTab />} /> : null}
                    {allowedTabs.includes("groups") ? <Route path="groups/*" element={<GroupsTab />} /> : null}
                    {allowedTabs.includes("customers") ? <Route path="customers" element={<CustomersTab />} /> : null}
                    <Route path="" element={<Navigate to={firstAllowedTab} />} />
                    <Route path="*" element={<Navigate to={routes.pageNotFound} />} />
                </Routes>
            </div>
        </main>
    );
});
