import { Grid, MenuItem } from "@material-ui/core";
import { Pagination, PaginationItem } from "@material-ui/lab";
import { LynxButton } from "components/LynxComponents/LynxButton/LynxButton";
import { LynxSelect } from "components/LynxComponents/LynxSelect/LynxSelect";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { LynxIcon } from "icons/LynxIcon";
import { observer } from "mobx-react";
import React from "react";
import { ThorPaginationProps } from "./ThorPaginationProps";
import { thorPaginationStyles } from "./ThorPaginationStyles";

export default observer(function ThorPagination(props: ThorPaginationProps) {
    const classes = thorPaginationStyles();

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        props.setPage(value);
    };

    const handleChanges = (event: React.ChangeEvent<{ value: unknown }>) => {
        const pageSize = parseInt(event.target.value as string);
        localStorage.setItem(props.localStorageItemName, JSON.stringify(pageSize));
        props.setPageSize(pageSize);
    };

    const getTableItemsName = () => {
        if (props.localStorageItemName === "eventsPageSize") {
            return "Events";
        } else if (props.localStorageItemName === "usersPageSize") {
            return "Users";
        } else if (props.localStorageItemName === "groupsPageSize") {
            return "Groups";
        } else if (props.localStorageItemName === "customersPageSize") {
            return "Customers";
        } else if (props.localStorageItemName === "stabilityFormHistoryPageSize") {
            return "Updates";
        } else if (props.localStorageItemName === "stabilityFormsPageSize") {
            return "Stability Forms";
        } else if (props.localStorageItemName === "batchHistoryPageSize") {
            return "Records";
        }
    };

    return (
        <Grid container className={classes.root} justifyContent="space-between">
            <Grid item></Grid>
            <Grid item>
                <Grid container>
                    <Grid item>
                        <LynxButton
                            variant="tertiary"
                            onClick={() => props.onPageChange("back")}
                            disabled={props.page <= 1}
                            leftIcon={<LynxIcon name="arrowLeft" />}
                        >
                            Prev
                        </LynxButton>
                    </Grid>
                    <Grid item>
                        <Pagination
                            count={props.pages}
                            page={props.page}
                            onChange={handleChange}
                            hideNextButton
                            hidePrevButton
                            className={classes.pagination}
                            renderItem={(item) => <PaginationItem {...item} />}
                        />
                    </Grid>
                    <Grid item>
                        <LynxButton
                            variant="tertiary"
                            onClick={() => props.onPageChange("forward")}
                            disabled={props.isLastPage}
                            rightIcon={<LynxIcon name="arrowRight" />}
                        >
                            Next
                        </LynxButton>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container direction="row" alignItems="baseline">
                    <Grid item>
                        <LynxTypography variant="body-s" color="neutral400">
                            {getTableItemsName()} per page{" "}
                        </LynxTypography>
                    </Grid>
                    <Grid item>
                        <LynxSelect
                            value={localStorage.getItem(props.localStorageItemName) ?? "10"}
                            onChange={handleChanges}
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: "top",
                                    horizontal: "right",
                                },
                                transformOrigin: {
                                    vertical: "bottom",
                                    horizontal: "right",
                                },
                            }}
                            className={classes.select}
                        >
                            {["5", "10", "15", "30"].map((item) => (
                                <MenuItem key={item} value={item}>
                                    {item}
                                </MenuItem>
                            ))}
                        </LynxSelect>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
});

