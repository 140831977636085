import { CircularProgress, CircularProgressProps, MenuProps, Select } from "@material-ui/core";
import clsx from "clsx";
import { LynxIcon } from "icons/LynxIcon";
import LynxTypography from "../LynxTypography/LynxTypography";
import { LynxSelectProps } from "./LynxSelectProps";
import { lynxSelectStyles } from "./LynxSelectStyles";

export const LynxSelect = (props: LynxSelectProps) => {
    const { className, loading, MenuProps, label, placeholderValue, ...otherProps } = props;

    const classes = lynxSelectStyles();

    const { anchorOrigin, transformOrigin, className: menuClassName, ...menuRest } = { ...MenuProps };

    const defaultMenuProps: Partial<MenuProps> = {
        anchorOrigin: {
            vertical: anchorOrigin?.vertical ?? "bottom",
            horizontal: anchorOrigin?.horizontal ?? "left",
        },
        transformOrigin: {
            vertical: transformOrigin?.vertical ?? "top",
            horizontal: transformOrigin?.horizontal ?? "left",
        },
        getContentAnchorEl: null,
        className: clsx(classes.menu, menuClassName),
    };

    const circularProgress = (props: CircularProgressProps) => <CircularProgress size="1rem" {...props} />;
    const finalClassName = clsx(classes.root, className, {
        [classes.placeholderText]: props.placeholderValue === props.value,
    });

    return (
        <>
            {!!label && typeof label === "string" ? (
                <LynxTypography variant="body-s" color="neutral400" className={classes.label}>
                    {label}
                </LynxTypography>
            ) : (
                label
            )}
            <Select
                className={finalClassName}
                IconComponent={(props) => {
                    const { className, ...rest } = props;

                    return loading ? (
                        circularProgress(props)
                    ) : (
                        <LynxIcon name="angleSmallDown" className={className} {...rest} />
                    );
                }}
                MenuProps={{
                    ...defaultMenuProps,
                    ...menuRest,
                }}
                disableUnderline
                {...otherProps}
            />
        </>
    );
};

