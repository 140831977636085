import clsx from "clsx";
import { LynxButton } from "components/LynxComponents/LynxButton/LynxButton";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { LynxIcon } from "icons/LynxIcon";
import { NotificationType } from "models/shared/NotificationType";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useStore } from "store/StoreConfigs";
import { IconFrame } from "./IconFrame";
import { PopupNotificationProps, PopupNotificationSettings } from "./PopupNotificationProps";
import { popupNotificationStyles } from "./PopupNotificationStyles";

export function PopupNotification(props: PopupNotificationProps) {
    const classes = popupNotificationStyles();
    const { commonStore } = useStore();
    const { type = NotificationType.INFORMATION, title, textContent, details, closeCallback } = props;

    const [expanded, setExpanded] = useState(false);

    const autoCloseInterval = 30000;

    const notificationSettings: PopupNotificationSettings = {
        [NotificationType.INFORMATION]: {
            defaultTitle: "Information",
            popupLeftBorderColor: classes.infoBorder,
            autocloseTime: autoCloseInterval,
        },
        [NotificationType.SUCCESS]: {
            defaultTitle: "Success!",
            popupLeftBorderColor: classes.successBorder,
            autocloseTime: autoCloseInterval,
        },
        [NotificationType.WARNING]: {
            defaultTitle: "Warning!",
            popupLeftBorderColor: classes.wargningBorder,
        },
        [NotificationType.ERROR]: {
            defaultTitle: "Error!",
            popupLeftBorderColor: classes.errorBorder,
        },
    };

    useEffect(() => {
        commonStore.incrementPopupsCount();
    }, []);

    const handleClose = () => {
        commonStore.decrementPopupsCount();

        if (commonStore.popupsCount === 0) {
            toast.dismiss("dismissAll");
        }

        closeCallback();
    };

    const { defaultTitle, popupLeftBorderColor, autocloseTime: autocloseTimeSetting } = notificationSettings[type];

    useEffect(() => {
        if (autocloseTimeSetting && autocloseTimeSetting > 0) {
            const timeoutId = setTimeout(() => {
                handleClose();
            }, autocloseTimeSetting);

            return () => clearTimeout(timeoutId);
        }
    }, [autocloseTimeSetting]);

    return (
        <div className={clsx(classes.container, popupLeftBorderColor)}>
            <IconFrame type={type} className={classes.iconContainerMargin} />
            <div className={clsx(classes.contentContainer)}>
                <LynxTypography variant="body-sbold-s" color="neutral600">
                    {title || defaultTitle}
                </LynxTypography>
                <div className={classes.textContainer}>
                    <LynxTypography variant="body-s" color="neutral600">
                        {textContent}
                    </LynxTypography>
                </div>
                {expanded && details && (
                    <div>
                        <LynxTypography variant="h3" color="neutral400" style={{ paddingTop: "0.5rem" }}>
                            DETAILS
                        </LynxTypography>
                        <div className={classes.textContainer}>
                            <LynxTypography variant="body-s">{details}</LynxTypography>
                        </div>
                    </div>
                )}

                {!!details && (
                    <LynxButton
                        className={classes.detailsButton}
                        variant="tertiary"
                        size="small"
                        onClick={() => setExpanded(!expanded)}
                    >
                        {!expanded ? "Details" : "Hide Details"}
                    </LynxButton>
                )}
            </div>
            <LynxButton variant="icon" onClick={handleClose} className={classes.closeIconContainer}>
                <LynxIcon name="crossSmall" />
            </LynxButton>
        </div>
    );
}

