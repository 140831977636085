import { Box, CircularProgress, InputAdornment } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { SearchLoadingIndicator } from "components/ReusableComponents/LoadingIndicator/SearchLoadingIndicator";
import { LynxAvatar } from "components/ReusableComponents/LynxAvatar/LynxAvatar";
import useDebounce from "hooks/useDebounce";
import { LynxIcon } from "icons/LynxIcon";
import { ChangeEvent, useEffect, useState } from "react";
import { LynxButton } from "../LynxButton/LynxButton";
import { LynxInput } from "../LynxInput/LynxInput";
import LynxTypography from "../LynxTypography/LynxTypography";
import { LynxAutocompleteProps, SearchOption } from "./LynxAutocompleteProps";
import { LynxAutocompleteStyles } from "./LynxAutocompleteStyles";

export default function LynxAutocomplete(props: LynxAutocompleteProps) {
    const [searchValue, setSearchValue] = useState("");
    const classes = LynxAutocompleteStyles();
    const debouncedValue = useDebounce<string>(searchValue, 800);

    const {
        onChange: onChangeProps,
        options,
        emptyOption,
        placeholder,
        initialOption,
        loading,
        label,
        avatarEmptyState,
        renderOptionsWithAvatars,
        cleanAfterSearch,
        search,
        value,
        showArrowIcon = true,
        ...rest
    } = { ...props };

    const optionsWithEmptyStateIfNotUndefined: SearchOption[] = emptyOption ? [emptyOption, ...options] : [...options];

    const handleSearch = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const inputValue = e.target.value.trimLeft().replace(/\s{2,}/g, " ") as string;

        if (inputValue.match(/[\\"]/) && inputValue !== "") {
            return;
        }

        setSearchValue(inputValue);
    };

    const renderOption = (option: SearchOption) => {
        return loading ? (
            <div>
                <SearchLoadingIndicator />
            </div>
        ) : renderOptionsWithAvatars ? (
            <>
                <Box display="flex" alignItems="center" gridGap="0.5rem">
                    <LynxAvatar firstName={option.id === "" ? avatarEmptyState ?? "-" : option.displayName} />
                    <LynxTypography>{option.displayName}</LynxTypography>
                </Box>
                {showArrowIcon && <LynxIcon name="arrowRight" className={classes.arrowRightIcon} />}
            </>
        ) : (
            <>
                <LynxTypography>{option.displayName}</LynxTypography>
                <LynxButton variant="tertiary">Select</LynxButton>
            </>
        );
    };

    useEffect(() => {
        if (debouncedValue) {
            !loading && search(searchValue.trim());
        }
    }, [debouncedValue]);

    return (
        <Autocomplete
            disableClearable
            defaultValue={initialOption}
            options={loading ? [{ id: "-1", displayName: "Loading..." }] : optionsWithEmptyStateIfNotUndefined}
            onChange={onChangeProps}
            filterOptions={(options) => options}
            getOptionLabel={(option) => (option.id === "" ? "" : option.displayName)}
            getOptionSelected={(option, value) => option.id === value.id}
            loading={loading}
            renderOption={renderOption}
            classes={{
                listbox: classes.listBox,
                option: classes.option,
            }}
            // hack to clear input after search
            value={cleanAfterSearch ? "" : value}
            {...rest}
            renderInput={(params) => (
                <div ref={params.InputProps.ref}>
                    <LynxInput
                        name={props.inputProps?.name}
                        error={props.inputProps?.error}
                        assistiveText={props.inputProps?.assistiveText}
                        value={searchValue}
                        onChange={props.inputProps?.onChange ?? handleSearch}
                        label={label}
                        placeholder={placeholder}
                        {...params}
                        endAdornment={
                            loading ? (
                                <CircularProgress size="1rem" />
                            ) : (
                                <InputAdornment position="end">
                                    <LynxIcon
                                        name="search"
                                        cursor={"pointer"}
                                        onClick={() => {
                                            if (!loading) {
                                                search(searchValue.trim());
                                            }
                                        }}
                                    />
                                </InputAdornment>
                            )
                        }
                    />
                </div>
            )}
        />
    );
}

