import { FormikContextType } from "formik";
import { RangeOperatorTo, RangeOperatorFrom } from "models/productAndStabilityForm/productAndStabilityFormModels";
import { ChangeEvent } from "react";

export const allCountriesAndRegions = "All Countries & Regions";

export const handleChangeRangeOperatorFrom = <T>(
    e: ChangeEvent<{
        name?: string | undefined;
        value: unknown;
    }>,
    formik: FormikContextType<T>,
    operatorFromFieldName: string,
    lowerLimitFieldName: string
) => {
    formik.setFieldValue(operatorFromFieldName, e.target.value);

    if (e.target.value === RangeOperatorFrom.INFINITY) {
        formik.setFieldValue(lowerLimitFieldName, "");
    }
};

export const handleChangeRangeOperatorTo = <T>(
    e: ChangeEvent<{
        name?: string | undefined;
        value: unknown;
    }>,
    formik: FormikContextType<T>,
    operatorToFieldName: string,
    upperLimitFieldName: string
) => {
    formik.setFieldValue(operatorToFieldName, e.target.value);

    if (e.target.value === RangeOperatorTo.INFINITY) {
        formik.setFieldValue(upperLimitFieldName, "");
    }
};

// functions for setting range operators
export const defineRangeOperatorFrom = (lowValue: string) => {
    if (lowValue.length === 0) {
        return RangeOperatorFrom.INFINITY;
    }

    return RangeOperatorFrom.GREATER_THAN_OR_EQUAL;
};

export const defineRangeOperatorTo = (highValue: string) => {
    if (highValue.length === 0) {
        return RangeOperatorTo.INFINITY;
    }

    return RangeOperatorTo.LESS_THAN_OR_EQUAL;
};

// functions for correcting string values for api requests
export const correctStringValueForCreateRequest = (value: string) => {
    const trimmed = value.trim();

    return trimmed === "" ? undefined : trimmed;
};

export const correctStringValueForEditRequest = (value: string, compareValue: string) => {
    const trimmed = value.trim();

    return trimmed === compareValue ? undefined : trimmed;
};

export const newOrNullIfSame = <T>(newValue: T, oldValue: T): T | null => (newValue === oldValue ? null : newValue);
