import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { LynxButton } from "components/LynxComponents/LynxButton/LynxButton";
import { observer } from "mobx-react";
import clsx from "clsx";
import { userManagementStyles } from "./tabs/userManagementStyles";

export interface UserActionOption {
    optionName: string;
    action: () => void;
    withImmediateAction?: boolean;
}
export interface UserActionProps {
    options: UserActionOption[];
}

export default observer(function UserActions({ options }: UserActionProps) {
    const classes = userManagementStyles();

    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLDivElement>(null);
    const [selectedOption, setSelectedOption] = useState<UserActionOption>({
        optionName: options[0].optionName,
        action: options[0].action,
    });

    const [width, setWidth] = useState(150);

    const handleClick = () => {
        selectedOption.action();

        setSelectedOption({
            optionName: options[0].optionName,
            action: options[0].action,
        });
    };

    const handleMenuItemClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, option: UserActionOption) => {
        setSelectedOption(option);
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }

        setOpen(false);
    };

    useEffect(() => {
        if (anchorRef.current) {
            setWidth(anchorRef.current.offsetWidth);
        }
    }, [selectedOption]);

    return (
        <Grid container direction="column" alignItems="flex-end">
            <Grid item>
                <ButtonGroup variant="contained" ref={anchorRef}>
                    <LynxButton variant="primary" onClick={handleClick} style={{ borderRight: "none" }}>
                        {selectedOption.optionName}
                    </LynxButton>
                    <LynxButton
                        variant="primary"
                        className={classes.actionButtonDivider}
                        aria-controls={open ? "split-button-menu" : undefined}
                        aria-expanded={open ? "true" : undefined}
                        aria-label="select merge strategy"
                        aria-haspopup="menu"
                        onClick={handleToggle}
                    >
                        <ArrowDropDownIcon
                            className={clsx({
                                [classes.icon]: open,
                            })}
                        />
                    </LynxButton>
                </ButtonGroup>
                <Popper
                    className={classes.actionPopper}
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition
                    modifiers={{
                        preventOverflow: {
                            enabled: true,
                            boundariesElement: "scrollParent",
                        },
                    }}
                    placement="bottom-start"
                    style={{ minWidth: `${width}px` }}
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin: placement === "bottom" ? "center top" : "center bottom",
                            }}
                        >
                            <Paper className={classes.actionPaper}>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList id="split-button-menu">
                                        {options
                                            .filter((x) => x.optionName !== selectedOption.optionName)
                                            .map((option) => (
                                                <MenuItem
                                                    key={option.optionName}
                                                    selected={option.optionName === selectedOption.optionName}
                                                    onClick={(event) =>
                                                        option.withImmediateAction
                                                            ? option.action()
                                                            : handleMenuItemClick(event, option)
                                                    }
                                                >
                                                    {option.optionName}
                                                </MenuItem>
                                            ))}
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </Grid>
        </Grid>
    );
});
