import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const attachmentsStyles = makeStyles((theme: Theme) =>
    createStyles({
        gridMarginBottomMedium: {
            marginBottom: "1rem",
        },

        gridMarginBotomSmall: {
            marginBottom: "0.5rem",
        },

        typographyWithIcon: {
            display: "inline-flex",
            alignItems: "center",
            gap: "0.5rem",
        },

        attachmentIcon: {
            fill: theme.colors.primary.blue300,
            minWidth: "16px",
        },

        gridsBorders: {
            "& > div": {
                borderBottom: `1px solid ${theme.colors.primary.blue100}`,
                padding: "0.5rem",
            },

            "& > :first-child": {
                borderTop: `1px solid ${theme.colors.primary.blue100}`,
            },
        },

        iconsContainer: {
            display: "flex",
            gap: "0.5rem",
        },

        noAttachmentsStateText: {
            marginBottom: "0.5rem",
        },

        dateMinWidth: {
            minWidth: "8.75rem",
        },

        gridGap: {
            justifyContent: "space-between",
            alignItems: "flex-end",
            flexWrap: "nowrap",
            gap: "0.5rem",
        },

        fileContainer: {
            overflowWrap: "break-word",
            wordWrap: "break-word",
            msWordBreak: "break-all",
            wordBreak: "break-word",
            gap: "0.5rem",
            justifyContent: "space-between",
            flexWrap: "nowrap",
        },

        loadingCommentsContainer: {
            width: "40%",
        },

        disabledContainer: {
            opacity: 0.5,
        },
    })
);

