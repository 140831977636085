import { TableCell, TableRow } from "@material-ui/core";
import clsx from "clsx";
import { LynxButton } from "components/LynxComponents/LynxButton/LynxButton";
import { PromptTooltip } from "components/ReusableComponents/PromptTooltip/PromptTooltip";
import LynxDurationInputForm from "components/ReusableForms/LynxDurationInputForm";
import LynxInputForm from "components/ReusableForms/LynxInputForm";
import { useFormikContext } from "formik";
import { LynxIcon } from "icons/LynxIcon";
import { promptTooltipMessages } from "lynxConstants";
import { StabilityFormModel } from "models/productAndStabilityForm/productAndStabilityFormModels";
import { stabilityFormStyles } from "../StabilityFormStyles";
import { FlowFieldsAreaProps } from "./Props/FlowFieldsAreaProps";

const FlowFieldsArea = (props: FlowFieldsAreaProps) => {
    const formik = useFormikContext<StabilityFormModel>();
    const classes = stabilityFormStyles();
    const { rangeIndex, regionIndex, flowIndex, flowHelpers } = { ...props };
    const flows = formik.values.temperatureRanges[rangeIndex].rangeRegions[regionIndex].flows;
    const flow = flows[props.flowIndex];

    const flowTableCells = [
        {
            key: 0,
            placeholder: "Enter Name",
            disabled: false,
            name: `rangeRegions.${regionIndex}.flows.${flowIndex}.name`,
            isStabilityTimeInput: false,
            width: "13%",
        },
        {
            key: 1,
            placeholder: "Enter Stops",
            disabled: false,
            name: `rangeRegions.${regionIndex}.flows.${flowIndex}.steps`,
            isStabilityTimeInput: false,
            width: "20%",
        },
        {
            key: 2,
            disabled: false,
            name: `rangeRegions.${regionIndex}.flows.${flowIndex}.plannedDeduction`,
            isStabilityTimeInput: true,
            width: "16%",
        },
        {
            key: 3,
            disabled: false,
            name: `rangeRegions.${regionIndex}.flows.${flowIndex}.remainingStabilityBudget`,
            isStabilityTimeInput: true,
            width: "16%",
        },
        {
            key: 4,
            placeholder: "Enter References",
            disabled: false,
            name: `rangeRegions.${regionIndex}.flows.${flowIndex}.references`,
            isStabilityTimeInput: false,
            width: "35%",
        },
    ];

    return (
        <TableRow className={clsx(classes.tableContentRow, classes.verticalAlignmentBaseLine)}>
            {flowTableCells.map((x) => (
                <TableCell
                    key={x.key}
                    width={x.width}
                    className={clsx(classes.paddingBottomZero, classes.paddingRightZero)}
                >
                    {x.isStabilityTimeInput ? (
                        <LynxDurationInputForm
                            name={`temperatureRanges.${rangeIndex}.${x.name}`}
                            placeholder={x.placeholder}
                            disabled={x.disabled}
                        />
                    ) : (
                        <LynxInputForm
                            multiline
                            name={`temperatureRanges.${rangeIndex}.${x.name}`}
                            placeholder={x.placeholder}
                            disabled={x.disabled}
                        />
                    )}
                </TableCell>
            ))}
            <TableCell className={classes.verticalAlignMiddle}>
                <PromptTooltip
                    placement="top"
                    title={
                        flow.isReferenced
                            ? promptTooltipMessages.stabilityForm.flowCannotBeDeletedBecauseReferenced
                            : promptTooltipMessages.empty
                    }
                >
                    <LynxButton
                        variant="icon"
                        size="medium"
                        disabled={flows.length <= 1 || flow.isReferenced}
                        onClick={() => {
                            if (flows.length <= 1) {
                                return;
                            }

                            flowHelpers.remove(flowIndex);
                        }}
                    >
                        <LynxIcon name="trash" />
                    </LynxButton>
                </PromptTooltip>
            </TableCell>
        </TableRow>
    );
};

export default FlowFieldsArea;

