import { TableCell, TableRow } from "@material-ui/core";
import { HumanReadableAuditEntryModel } from "api/models/audit/auditApi";
import clsx from "clsx";
import { LynxButton } from "components/LynxComponents/LynxButton/LynxButton";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { Span } from "components/LynxComponents/LynxTypography/Span";
import { commonConstants } from "lynxConstants";
import dayjs from "dayjs";
import { dateToFormat } from "helpers/dateFormattingHelper";
import { getFormatedTimezoneOffset } from "helpers/timezoneHelper";
import { useEffect, useState } from "react";
import { AuditEntryPayloadRenderer } from "../AuditEntryPayloadRenderer/AuditEntryPayloadRenderer";
import { LynxAvatarWithTooltip } from "../LynxAvatar/LynxAvatarWithTooltip";
import { auditSectionStyles } from "./AuditSectionStyles";

export interface AuditEntryTableRowProps {
    entry: HumanReadableAuditEntryModel;
    forceExpand: boolean;
    className?: string;
    timezone?: string;
}

// Combine with Table in EventAuditPage and move to separate component
export const AuditEntryTableRow = (props: AuditEntryTableRowProps) => {
    const { entry, className } = props;
    const classes = auditSectionStyles();

    const [isExpanded, setisExpanded] = useState(props.forceExpand);
    const toggleExpanded = () => setisExpanded((x) => !x);
    const actionName = entry.payload?.initialEventDisplayId
        ? `${entry.actionName} (${entry.payload?.initialEventDisplayId})`
        : entry.actionName;
    const summaryCellClassName = clsx(className, isExpanded && classes.expandedRow);

    useEffect(() => {
        setisExpanded(props.forceExpand);
    }, [props.forceExpand]);

    const renderPayload = () => {
        if (!entry.payloadExists) {
            return (
                <div>
                    <LynxTypography>This business action does not have a detailed view</LynxTypography>
                </div>
            );
        }

        if (!entry.payloadParserDefined) {
            return (
                <div>
                    <LynxTypography>Detailed view for this business action is not built yet</LynxTypography>
                </div>
            );
        }

        if (!entry.payloadParsedSuccessfully) {
            return (
                <div>
                    <LynxTypography>Error occured when processing details for this business action</LynxTypography>
                </div>
            );
        }

        if (!entry.payload) {
            throw new Error("Payload should exist at this point.");
        }

        return (
            <AuditEntryPayloadRenderer
                forceExpand={props.forceExpand}
                payload={entry.payload}
                timezone={props.timezone}
            />
        );
    };

    return (
        <>
            <TableRow>
                <TableCell className={summaryCellClassName}>
                    <LynxTypography variant="body-l">{actionName}</LynxTypography>
                </TableCell>
                <TableCell className={summaryCellClassName}>
                    <LynxTypography variant="body-l">
                        {`${dateToFormat(entry.timestamp, commonConstants.fullDateTimeFormat)}
                        ${getFormatedTimezoneOffset(entry.timestamp, dayjs.tz.guess())}`}
                    </LynxTypography>
                </TableCell>
                <TableCell className={summaryCellClassName}>
                    {entry.userId === commonConstants.system ? (
                        <LynxTypography variant="body-l">System</LynxTypography>
                    ) : (
                        <div className={classes.userContainer}>
                            <LynxAvatarWithTooltip
                                email={entry.userId}
                                firstName={entry.userFirstName || ""}
                                lastName={entry.userLastName || ""}
                            />
                            <Span>
                                {entry.userFirstName} {entry.userLastName}
                            </Span>
                        </div>
                    )}
                </TableCell>
                <TableCell className={clsx(summaryCellClassName, classes.buttonColumn)} align="right">
                    <LynxButton variant={isExpanded ? "secondary" : "primary"} onClick={toggleExpanded}>
                        {isExpanded ? "Hide details" : "See details"}
                    </LynxButton>
                </TableCell>
            </TableRow>
            {isExpanded && (
                <TableRow>
                    <TableCell className={clsx(className, classes.expandedCell)} colSpan={4}>
                        {renderPayload()}
                    </TableCell>
                </TableRow>
            )}
        </>
    );
};
