import { Grid, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { ListStabilityFormsRequest } from "api/models/stabilityForms/stabilityFormsApi";
import clsx from "clsx";
import GeneralErrorPage from "components/ErrorComponents/GeneralErrorPage";
import { LynxButton } from "components/LynxComponents/LynxButton/LynxButton";
import { LynxRedirectLink } from "components/LynxComponents/LynxRedirectLink/LynxRedirectLink";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { FiltersComponent } from "components/ReusableComponents/FiltersComponent/FiltersComponent";
import FiltersResultEmptyState from "components/ReusableComponents/FiltersComponent/FiltersResultEmptyState";
import { FullScreenLoadingIndicator } from "components/ReusableComponents/LoadingIndicator/FullScreenLoadingIndicator";
import { LynxAvatarWithTooltip } from "components/ReusableComponents/LynxAvatar/LynxAvatarWithTooltip";
import ThorPagination from "components/ReusableComponents/ThorPagination/ThorPagination";
import { commonConstants } from "lynxConstants";
import { dateToFormat } from "helpers/dateFormattingHelper";
import { parseJsonForRequest } from "helpers/jsonParser";
import { getPermissionKey } from "helpers/permissionHelpers";
import { observer } from "mobx-react";
import { actions } from "models/userManagement/actions";
import { MarvelPermissionStatus } from "models/userManagement/userManagementModels";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import routes from "routes";
import { useStore } from "store/StoreConfigs";
import { stabilityFormsStyles } from "./StabilityFormStyles";

export const StabilityFormsView = observer(() => {
    const { stabilityFormStore, identityStore, userStore, permissionsStore, commonStore } = useStore();
    const navigate = useNavigate();
    const classes = stabilityFormsStyles();
    const tableHeaders = ["Product Name", "Dose Form/Dosage", "Form Number", "Effective Date", "Form Owner"];

    const loadForms = () => {
        const request: ListStabilityFormsRequest = {
            customerId: identityStore.currentCustomer.id,
            pageNumber: stabilityFormStore.currentPage,
            pageSize: stabilityFormStore.pageSize,
            formOwners: parseJsonForRequest(stabilityFormStore.formOwnerSelectedFilters),
            searchValue: stabilityFormStore.searchInputTrimmed,
            searchParameter: stabilityFormStore.searchParameter,
        };

        stabilityFormStore.loadStabilityForms(request);
    };

    const permissionKey = getPermissionKey(actions.customer.tor.stabilityForms.view, identityStore.currentCustomer.id);
    const permissionStatus = permissionsStore.getPermissionStatus(permissionKey);

    useEffect(() => {
        if (identityStore.isSystemSpace || permissionStatus === MarvelPermissionStatus.Deny) {
            navigate(identityStore.startPageAvailable);
        }
    }, [identityStore.isSystemSpace, identityStore.startPageAvailable]);

    const viewDetailsPermissionKey = getPermissionKey(
        actions.customer.tor.stabilityForms.viewDetails,
        identityStore.currentCustomer.id
    );

    const modifyPermissionKey = getPermissionKey(
        actions.customer.tor.stabilityForms.modify,
        identityStore.currentCustomer.id
    );

    useEffect(() => {
        if (permissionStatus !== MarvelPermissionStatus.Allow) {
            return;
        }

        loadForms();

        return () => commonStore.setShowGeneralErrorPageToFalse();
    }, [permissionStatus, stabilityFormStore.currentPage, stabilityFormStore.pageSize]);

    if (permissionStatus !== MarvelPermissionStatus.Allow) {
        return <FullScreenLoadingIndicator />;
    }

    const title = `Stability Data Management (${stabilityFormStore.totalCount})`;

    return (
        <main className={classes.root}>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <Grid container justifyContent="space-between">
                <Grid item xs={10}>
                    <FiltersComponent
                        title={title}
                        filters={[
                            {
                                options: userStore.customerUsers.map((x) => ({
                                    itemDisplayName: `${x.firstName} ${x.lastName}`,
                                    itemValue: x.id,
                                })),
                                selectedOptions: stabilityFormStore.formOwnerSelectedFilters,
                                label: "Form Owner",
                                changeFunction: stabilityFormStore.setFormOwnerFilter,
                                isUsersDropdown: true,
                                isOptionsDownloaded: userStore.progressFlags.loadingUsersForDropdown,
                            },
                        ]}
                        request={loadForms}
                        resetFilters={stabilityFormStore.resetAllFilters}
                        search={{
                            options: [
                                { label: "Product Name", parameter: "ProductName" },
                                { label: "Form #", parameter: "FormNumber" },
                            ],
                            searchParameter: stabilityFormStore.searchParameter,
                            searchValue: stabilityFormStore.searchValue,
                            setSearchValue: stabilityFormStore.setSearchValue,
                            setSearchParameter: stabilityFormStore.setSearchParameter,
                        }}
                    />
                </Grid>
                <Grid item>
                    <LynxButton
                        size="medium"
                        onClick={() => navigate(routes.stabilityFormsCreate)}
                        disabled={!permissionsStore.hasPermission(modifyPermissionKey)}
                        loading={permissionsStore.permissionLoading(modifyPermissionKey)}
                    >
                        Add Stability Form
                    </LynxButton>
                </Grid>
            </Grid>

            {stabilityFormStore.progressFlags.loadingStabilityForms ? (
                <div>
                    <FullScreenLoadingIndicator />
                </div>
            ) : commonStore.showGeneralErrorPage ? (
                <GeneralErrorPage />
            ) : (
                <div className={classes.tableContainer}>
                    {stabilityFormStore.stabilityForms.length === 0 ? (
                        <FiltersResultEmptyState />
                    ) : (
                        <>
                            <Table>
                                <TableHead>
                                    <TableRow className={classes.headerRow}>
                                        {tableHeaders.map((header) => (
                                            <TableCell
                                                className={clsx(classes.cellBorderBottom, classes.headerCell)}
                                                key={header}
                                            >
                                                <LynxTypography color="neutral400" variant="h3">
                                                    {header}
                                                </LynxTypography>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {stabilityFormStore.stabilityForms.map((stabilityForm) => (
                                        <React.Fragment key={stabilityForm.id}>
                                            <TableRow className={classes.tableRow}>
                                                <TableCell className={classes.cellBorderBottom}>
                                                    <LynxTypography variant="body-l" className={classes.fieldMaxWidth}>
                                                        <LynxRedirectLink
                                                            to={routes.stabilityFormDetails.to(stabilityForm.id)}
                                                            disabled={
                                                                !permissionsStore.hasPermission(
                                                                    viewDetailsPermissionKey
                                                                )
                                                            }
                                                            loading={permissionsStore.permissionLoading(
                                                                viewDetailsPermissionKey
                                                            )}
                                                        >
                                                            {stabilityForm.productName}
                                                        </LynxRedirectLink>
                                                    </LynxTypography>
                                                </TableCell>
                                                <TableCell className={classes.cellBorderBottom}>
                                                    <LynxTypography variant="body-l" className={classes.fieldMaxWidth}>
                                                        {stabilityForm.productRepresentations
                                                            .map(
                                                                (x) =>
                                                                    `${x.doseForm} (${
                                                                        x.allDosagesFlag
                                                                            ? "All"
                                                                            : `${x.dosage} ${x.unitOfMeasure}`
                                                                    })`
                                                            )
                                                            .join(", ")}
                                                    </LynxTypography>
                                                </TableCell>
                                                <TableCell className={classes.cellBorderBottom}>
                                                    <LynxTypography variant="body-l" className={classes.fieldMaxWidth}>
                                                        {stabilityForm.number ?? commonConstants.emptyValue}
                                                    </LynxTypography>
                                                </TableCell>
                                                <TableCell className={classes.cellBorderBottom}>
                                                    <LynxTypography variant="body-l">
                                                        {dateToFormat(
                                                            stabilityForm.effectiveDate,
                                                            commonConstants.shortDateFormat,
                                                            true
                                                        )}
                                                    </LynxTypography>
                                                </TableCell>
                                                <TableCell className={classes.cellBorderBottom}>
                                                    <LynxTypography variant="body-l">
                                                        {stabilityForm.ownerEmail ? (
                                                            <LynxAvatarWithTooltip
                                                                email={stabilityForm.ownerEmail}
                                                                firstName={stabilityForm.ownerFirstName}
                                                                lastName={stabilityForm.ownerLastName}
                                                            />
                                                        ) : (
                                                            commonConstants.emptyValue
                                                        )}
                                                    </LynxTypography>
                                                </TableCell>
                                            </TableRow>
                                            <br />
                                        </React.Fragment>
                                    ))}
                                </TableBody>
                            </Table>
                            {stabilityFormStore.stabilityForms.length > 0 && (
                                <ThorPagination
                                    page={stabilityFormStore.currentPage}
                                    pages={stabilityFormStore.totalPages}
                                    onPageChange={stabilityFormStore.moveToPage}
                                    isLastPage={stabilityFormStore.isLastPage}
                                    setPage={stabilityFormStore.setPage}
                                    localStorageItemName={"stabilityFormsPageSize"}
                                    setPageSize={stabilityFormStore.setPageSize}
                                />
                            )}
                        </>
                    )}
                </div>
            )}
        </main>
    );
});
