import { Grid } from "@material-ui/core";
import clsx from "clsx";
import { LynxButton } from "components/LynxComponents/LynxButton/LynxButton";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { convertDateToSelectedTimezone, dateInLocalTimezone } from "helpers/timezoneHelper";
import { LynxIcon } from "icons/LynxIcon";
import { commonConstants } from "lynxConstants";
import { observer } from "mobx-react";
import { attachmentsStyles } from "./AttachmentsStyles";
import { LynxAttachmentsProps } from "./LynxAttachmentsProps";

export default observer(function DisplayAttachments({
    attachments,
    progressFlag,
    timezone,
    isRemoveDisabled,
    downloadFile,
    handleRemoveAttachment,
}: LynxAttachmentsProps) {
    const classes = attachmentsStyles();

    return attachments.length > 0 ? (
        <Grid
            item
            container
            direction="column"
            className={clsx(
                classes.gridMarginBottomMedium,
                classes.gridsBorders,
                progressFlag && classes.disabledContainer
            )}
        >
            {attachments.map((attachment) => (
                <Grid item container key={attachment.id} direction="column">
                    <Grid item container className={clsx(classes.gridMarginBotomSmall, classes.fileContainer)}>
                        <LynxTypography className={classes.typographyWithIcon}>
                            <LynxIcon name="attachments" className={classes.attachmentIcon} />
                            {attachment.fileName}
                        </LynxTypography>

                        <Grid item className={classes.iconsContainer}>
                            <LynxButton
                                size="small"
                                variant="icon"
                                disabled={progressFlag}
                                onClick={() => downloadFile(attachment)}
                            >
                                <LynxIcon name="download" />
                            </LynxButton>
                            <LynxButton
                                disabled={isRemoveDisabled}
                                size="small"
                                variant="icon"
                                onClick={(e) => handleRemoveAttachment(attachment)}
                            >
                                <LynxIcon name="trash" />
                            </LynxButton>
                        </Grid>
                    </Grid>
                    <Grid item container className={classes.gridGap}>
                        <Grid item>
                            <LynxTypography
                                variant="body-s"
                                color="neutral300"
                            >{`Added by ${attachment.firstName} ${attachment.lastName}`}</LynxTypography>
                        </Grid>
                        <Grid item className={classes.dateMinWidth}>
                            <LynxTypography variant="body-s" color="neutral300">
                                {(timezone
                                    ? convertDateToSelectedTimezone(attachment.createdAt, timezone)
                                    : dateInLocalTimezone(attachment.createdAt)
                                ).format(commonConstants.fullDateTimeFormat)}
                            </LynxTypography>
                        </Grid>
                    </Grid>
                </Grid>
            ))}
        </Grid>
    ) : (
        <LynxTypography
            variant="body-s"
            color="neutral300"
            className={clsx(classes.noAttachmentsStateText, progressFlag && classes.disabledContainer)}
        >
            No attachments here yet
        </LynxTypography>
    );
});

