export function areAllFieldsNullDeepCheck(obj: any): boolean {
    for (const key in obj) {
        if (typeof obj[key] === "object" && obj[key] !== null && !(obj[key] instanceof Date)) {
            if (!areAllFieldsNullDeepCheck(obj[key])) {
                return false;
            }
        } else {
            if (obj[key] !== undefined && obj[key] !== null) {
                return false;
            }
        }
    }

    return true;
}
