import * as eventsApi from "api/models/events/eventsApi";
import { services } from "api/serviceConfig";
import clsx from "clsx";
import { LynxButton } from "components/LynxComponents/LynxButton/LynxButton";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { CenteredLoadingIndicator } from "components/ReusableComponents/LoadingIndicator/CenteredLoadingIndicator";
import { LynxModal } from "components/ReusableComponents/LynxModal/LynxModal";
import { toastsText } from "helpers/toastTextHelper";
import { LynxIcon } from "icons/LynxIcon";
import { observer } from "mobx-react";
import { NotificationType } from "models/shared/NotificationType";
import React, { useState } from "react";
import { useStore } from "store/StoreConfigs";
import { UploadAreaProps } from "./UploadAreaProps";
import { uploadAreaStyles } from "./UploadAreaStyles";

export const UploadArea = observer((props: UploadAreaProps) => {
    const { thorEventViewStore, identityStore } = useStore();
    const [uploadState, setUploadState] = useState(new Map());
    const classes = uploadAreaStyles();
    const { isEventCreatingOrEditing, onFileUploadNotification, addAttachment } = props;

    const [drag, setDrag] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const dragStartHandler = (e: React.DragEvent<HTMLDivElement>): void => {
        e.preventDefault();
        setDrag(true);
    };

    const dragLeaveHandler = (e: React.DragEvent<HTMLDivElement>): void => {
        e.preventDefault();
        setDrag(false);
    };

    const uploadStatusCallback = (data: ProgressEvent, attachmentId: string) => {
        if (!uploadState.has(attachmentId)) {
            const mapData = uploadState.set(attachmentId, (data.loaded * 100) / data.total);
            setUploadState(new Map(mapData));
        } else {
            setUploadState(new Map(uploadState.set(attachmentId, (data.loaded * 100) / data.total)));
        }
    };

    const uploadToS3AndConfirm = async (file: File) => {
        if (thorEventViewStore.progressFlags.uploadingAttachments) {
            return;
        }

        thorEventViewStore.setUploadingAttachmentsFlag(true);

        try {
            const getUploadPresignedUrlRequest: eventsApi.GetUploadPresignedUrlRequest = {
                fileName: file.name,
                eventId: thorEventViewStore.eventDetails.id,
                customerId: identityStore.currentCustomer.id,
                contentType: file.type,
            };

            const urlResponse = isEventCreatingOrEditing
                ? await services.Events.getUploadPresignedUrl(getUploadPresignedUrlRequest)
                : await services.Events.getUploadPresignedUrlAndSave(getUploadPresignedUrlRequest);

            const uploadToS3Request: eventsApi.UploadToS3Request = {
                file: file,
                url: urlResponse.data.url,
                uploadProgressCallback: (event: ProgressEvent) =>
                    uploadStatusCallback(event, urlResponse.data.fileData.id),
            };

            const response = await services.Events.uploadToS3WithPresignedUrl(uploadToS3Request);

            if (response.status !== 200) {
                onFileUploadNotification(NotificationType.ERROR, toastsText.fileNotUploaded(file.name));
                return;
            }

            if (!isEventCreatingOrEditing) {
                const confirmRequest: eventsApi.ConfirmFileUploadRequest = {
                    customerId: identityStore.currentCustomer.id,
                    eventId: thorEventViewStore.eventDetails.id,
                    attachmentId: urlResponse.data.fileData.id,
                };

                await services.Events.confirmFileUpload(confirmRequest);
            }

            if (addAttachment) {
                addAttachment(urlResponse.data.fileData as any);
            }

            onFileUploadNotification(NotificationType.SUCCESS, toastsText.fileSuccessfullyUploaded(file.name));
        } catch (error) {
            onFileUploadNotification(NotificationType.ERROR, toastsText.fileNotUploaded(file.name));
        } finally {
            thorEventViewStore.setUploadingAttachmentsFlag(false);
        }
    };

    const uploadFiles = async (files: File[]) => {
        for (const file of files) {
            await uploadToS3AndConfirm(file);
        }

        thorEventViewStore.incrementAttachmentsFirstSetCount();
        thorEventViewStore.setFileAttachModalOpen(false);
    };

    const validateFiles = (files: File[] | FileList) => {
        if (files.length <= 0) return true;

        // max 30MB
        for (let index = 0; index < files.length; index++) {
            if (files[index].size > 31457280) {
                setErrorMessage("Max 30MB file size allowed");
                return false;
            }
        }

        return true;
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        e.preventDefault();
        if (e.target.files === null || thorEventViewStore.progressFlags.uploadingAttachments) {
            return;
        }

        if (!validateFiles(e.target.files)) return;

        uploadFiles(Array.from(e.target.files));
        e.target.value = "";
    };

    const onDropHandler = (e: React.DragEvent<HTMLDivElement>): void => {
        e.preventDefault();
        const files: File[] = Array.prototype.slice.call(e.dataTransfer.files);

        if (files === null || files.length === 0 || thorEventViewStore.progressFlags.uploadingAttachments) {
            return;
        }

        if (!validateFiles(files)) {
            setDrag(false);
            return;
        }

        uploadFiles(files);
        setDrag(false);
    };

    const handleModalClose = () => {
        thorEventViewStore.setFileAttachModalOpen(false);
        setErrorMessage(null);
    };

    const handleInputClick = () => {
        document.getElementById("files")!.click();
    };

    return (
        <LynxModal
            open={thorEventViewStore.isFileAttachModalOpen}
            onClose={handleModalClose}
            onConfirm={handleModalClose}
            header="Add a New Document"
            title="Choose a file from your device you’d like to attach to this event."
        >
            <div className={classes.dropArea}>
                <div
                    className={clsx(classes.dragged, drag && classes.drag)}
                    onDragStart={(e) => dragStartHandler(e)}
                    onDragLeave={(e) => dragLeaveHandler(e)}
                    onDragOver={(e) => dragStartHandler(e)}
                    onDrop={(e) => onDropHandler(e)}
                >
                    {thorEventViewStore.progressFlags.uploadingAttachments ? (
                        <CenteredLoadingIndicator />
                    ) : (
                        <div className={classes.modalContainer}>
                            <LynxIcon name="attachments" className={classes.attachmentIcon} />
                            <LynxTypography variant="body-medium">Drag and Drop here</LynxTypography>
                            <LynxTypography>or</LynxTypography>
                            <LynxButton
                                onClick={handleInputClick}
                                disabled={thorEventViewStore.progressFlags.uploadingAttachments}
                            >
                                Select a file
                            </LynxButton>
                            <input
                                id="files"
                                onChange={(e) => handleInputChange(e)}
                                name="files"
                                type="file"
                                multiple
                                className={classes.fileInputDisplayNone}
                                disabled={thorEventViewStore.progressFlags.uploadingAttachments}
                            />
                        </div>
                    )}
                </div>
                {!!errorMessage && (
                    <LynxTypography color="critical500" variant="body-s" className={classes.errorMessage}>
                        {errorMessage}
                    </LynxTypography>
                )}
            </div>
        </LynxModal>
    );
});

