import { services } from "api/serviceConfig";
import * as userManagementApi from "api/models/userManagement/userManagementApi";
import * as userManagementModels from "models/userManagement/userManagementModels";
import { makeAutoObservable } from "mobx";
import IdentityStore from "./IdentityStore";
import { PageModel } from "api/models/sharedModels/PageModel";
import { SharedProperties } from "helpers/typeHelpers";
import { actionsOrder } from "models/userManagement/actions";
import _ from "lodash";
import CommonStore from "./CommonStore";
import { GroupUserInfo } from "models/userManagement/userManagementModels";
import { handleErrors } from "helpers/handleErrors";

export class GroupStore {
    constructor(identityStore: IdentityStore, commonStore: CommonStore) {
        makeAutoObservable(this);
        this.identityStore = identityStore;
        this.commonStore = commonStore;
    }

    identityStore: IdentityStore;
    commonStore: CommonStore;

    groups: userManagementModels.GroupListItem[] = [];
    modulesForSelection: userManagementModels.MarvelModule[] = [];
    rolesForSelection: userManagementApi.RoleListItemResponse[] = [];
    permissionsForSelection: userManagementApi.PermissionListItemResponse[] = [];
    usersForSelection: GroupUserInfo[] = [];

    selectedGroup: userManagementModels.UserGroup = {
        id: "",
        name: "",
        role: null,
        permissions: [],
        status: userManagementModels.GroupStatus.Active,
        users: [],
        module: null,
        readonlyName: false,
        readonlyPermissions: false,
        readonlyStatus: false,
        isDeleted: false,
        isNotify: false,
        regions: [],
    };

    addGroupModel: userManagementModels.AddGroupModel = {
        name: "",
        roleId: "",
        moduleId: "",
        users: [],
        permissions: [],
        isNotify: false,
        allCountriesAndRegions: false,
        regions: [],
    };

    editGroupModel: userManagementModels.EditGroupModel = {
        name: "",
        roleId: "",
        moduleId: "",
        users: [],
        permissions: [],
        isNotify: false,
        allCountriesAndRegions: false,
        regions: [],
    };

    progressFlags = {
        loadingGroups: false,
        loadingModulesForSelection: false,
        loadingRolesForSelection: false,
        loadingPermissionsForSelection: false,
        loadingUsersForSelection: false,
        changingGroupStatus: false,
        loadingGroupDetails: false,
        addGroupRequest: false,
        editGroupRequest: false,
    };

    formErrors: string[] = [];
    clearErrors = () => (this.formErrors = []);
    setErrors = (value: string[]) => (this.formErrors = value);
    roleTouched = false;
    errorLoadingGroup = false;

    currentPage = 1;
    totalPages = 1;
    pageSize = parseInt(localStorage.getItem("groupsPageSize") ?? "10");

    get isLastPage() {
        return this.totalPages === 0 || this.currentPage === this.totalPages;
    }

    setPage = (page: number) => {
        this.currentPage = page;
    };

    movePage = (direction: "back" | "forward") => {
        direction === "back" ? this.currentPage-- : this.currentPage++;
    };

    setPageSize = (pageSize: number) => {
        this.pageSize = pageSize;
    };

    clearPagination = () => {
        this.groups = [];
        this.currentPage = 1;
        this.totalPages = 1;
    };

    setModulesForSelection = (values: userManagementModels.MarvelModule[]) => (this.modulesForSelection = values);

    setRolesForSelection = (values: userManagementApi.RoleListItemResponse[]) => (this.rolesForSelection = values);

    setPermissionsForSelection = (values: userManagementApi.PermissionListItemResponse[]) =>
        (this.permissionsForSelection = values);

    setUsersForSelection = (values: GroupUserInfo[]) => (this.usersForSelection = values);

    resetToRoleDefaults = () => {
        if (this.selectedGroup.id === "") {
            this.populatePermissions(this.addGroupModel, false);
        } else {
            this.roleTouched = false;
            this.populatePermissions(this.editGroupModel, false);
        }
    };

    loadGroups = async () => {
        this.progressFlags.loadingGroups = true;

        try {
            const request = { pageSize: this.pageSize, pageNumber: this.currentPage };

            const response = this.identityStore.isSystemSpace
                ? await services.Groups.listSystemGroups(request)
                : await services.Groups.listCustomerGroups({
                      ...request,
                      customerId: this.identityStore.currentCustomer.id,
                  });

            if (!_.inRange(response.status, 200, 300)) {
                this.commonStore.setShowGeneralErrorPageToTrue();
                return;
            }

            this.totalPages = response.data.totalPages;
            this.groups = response.data.items;
        } finally {
            this.progressFlags.loadingGroups = false;
        }
    };

    loadModulesForSelection = async () => {
        this.progressFlags.loadingModulesForSelection = true;

        try {
            const response = await services.Customers.listCustomerModules({
                customerId: this.identityStore.currentCustomer.id,
            });

            if (!_.inRange(response.status, 200, 300)) return;

            this.setModulesForSelection(response.data);
        } finally {
            this.progressFlags.loadingModulesForSelection = false;
        }
    };

    loadRolesForSelection = async () => {
        this.progressFlags.loadingRolesForSelection = true;

        try {
            const response = this.identityStore.isSystemSpace
                ? await services.Permissions.listSystemRoles()
                : await services.Permissions.listCustomerRoles({ customerId: this.identityStore.currentCustomer.id });

            if (!_.inRange(response.status, 200, 300)) return;

            this.setRolesForSelection(response.data);
        } finally {
            this.progressFlags.loadingRolesForSelection = false;
        }
    };

    loadPermissionsForSelection = async () => {
        this.progressFlags.loadingPermissionsForSelection = true;

        try {
            const response = this.identityStore.isSystemSpace
                ? await services.Permissions.listSystemPermissions()
                : await services.Permissions.listCustomerPermissions({
                      customerId: this.identityStore.currentCustomer.id,
                  });

            if (!_.inRange(response.status, 200, 300)) return;

            this.setPermissionsForSelection(response.data);
        } finally {
            this.progressFlags.loadingPermissionsForSelection = false;
        }
    };

    loadUsersForSelection = async () => {
        this.progressFlags.loadingUsersForSelection = true;

        try {
            const request: PageModel = { pageNumber: 1, pageSize: 10000 };

            const response = this.identityStore.isSystemSpace
                ? await services.Users.listSystemUsers(request)
                : await services.Users.listCustomerUsers({
                      ...request,
                      customerId: this.identityStore.currentCustomer.id,
                  });

            if (!_.inRange(response.status, 200, 300)) return;

            this.setUsersForSelection(response.data.items);
        } finally {
            this.progressFlags.loadingUsersForSelection = false;
        }
    };

    changeGroupStatus = async (groupId: string, status: userManagementModels.GroupStatus) => {
        this.progressFlags.changingGroupStatus = true;
        let response;

        try {
            const request = { fieldsToEdit: ["status"], groupId, status };

            if (this.identityStore.isSystemSpace) {
                response = await services.Groups.editSystemGroup(request);
            } else {
                response = await services.Groups.editCustomerGroup({
                    ...request,
                    customerId: this.identityStore.currentCustomer.id,
                });
            }

            if (!_.inRange(response.status, 200, 300)) return;

            const groupListItem = this.groups.find((x) => x.id === groupId);

            if (!groupListItem) {
                throw new Error("Bug, groupListItem should not be undefined here.");
            }

            groupListItem.status = status;
        } finally {
            this.progressFlags.changingGroupStatus = false;
        }
    };

    loadGroupDetails = async (groupId: string) => {
        this.progressFlags.loadingGroupDetails = true;

        try {
            const response = this.identityStore.isSystemSpace
                ? await services.Groups.getSystemGroupDetails({ groupId })
                : await services.Groups.getCustomerGroupDetails({
                      groupId,
                      customerId: this.identityStore.currentCustomer.id,
                  });

            if (!_.inRange(response.status, 200, 300)) return;

            this.selectedGroup = response.data;
        } finally {
            this.errorLoadingGroup = false;
            this.progressFlags.loadingGroupDetails = false;
        }
    };

    addGroup = async (callback: () => void) => {
        if (this.progressFlags.addGroupRequest) {
            return;
        }

        this.progressFlags.addGroupRequest = true;
        this.clearErrors();

        const model = this.addGroupModel;

        const request: SharedProperties<
            userManagementApi.CreateSystemGroupRequest,
            userManagementApi.CreateCustomerGroupRequest
        > = {
            name: model.name,
            roleId: model.roleId || undefined,
            users: model.users.map((x) => x.id),
            isNotify: model.isNotify,
            regions: model.regions,
            permissions: model.permissions
                .filter((x) => x.overrideType !== userManagementModels.PermissionOverrideType.None)
                .map((x) => ({ id: x.id, overrideType: x.overrideType })),
        };

        try {
            const response = this.identityStore.isSystemSpace
                ? await services.Groups.createSystemGroup({ ...request })
                : await services.Groups.createCustomerGroup({
                      ...request,
                      customerId: this.identityStore.currentCustomer.id,
                      moduleId: model.moduleId || undefined,
                  });

            if (!_.inRange(response.status, 200, 300)) {
                handleErrors(response, this.setErrors);
                return;
            }

            callback();
        } finally {
            this.progressFlags.addGroupRequest = false;
        }
    };

    sortComparer = (
        a: userManagementApi.PermissionListItemResponse,
        b: userManagementApi.PermissionListItemResponse
    ) => {
        const [indexA, indexB] = [actionsOrder.indexOf(a.id), actionsOrder.indexOf(b.id)];
        return indexA < indexB ? -1 : indexA > indexB ? 1 : 0;
    };

    populatePermissions = (
        model: { moduleId: string; roleId: string; permissions: userManagementModels.GroupPermissionInfo[] },
        checkSelectedGroup: boolean
    ) => {
        model.permissions = [];

        this.permissionsForSelection
            .filter((x) => !model.moduleId || x.scopedToModuleId === model.moduleId)
            .sort(this.sortComparer)
            .forEach((x) => {
                const permissionInfo: userManagementModels.GroupPermissionInfo = {
                    id: x.id,
                    name: x.name,
                    description: x.description,
                    section: x.section,
                    overrideType: userManagementModels.PermissionOverrideType.None,
                    includedInRole: false,
                };

                model.permissions.push(permissionInfo);
            });

        model.permissions.forEach((x) => {
            if (model.roleId) {
                const role = this.rolesForSelection.find((y) => y.id === model.roleId);

                if (!role) {
                    throw new Error("bug, role should not be undefined here");
                }

                x.includedInRole = role.permissions.map((y) => y.id).includes(x.id);
            }
        });

        if (checkSelectedGroup) {
            model.permissions.forEach((x) => {
                const permission = this.selectedGroup.permissions.find((y) => y.id === x.id);

                if (permission) {
                    x.overrideType = permission.overrideType;
                }
            });
        }
    };

    populateEditModel = () => {
        this.editGroupModel.name = this.selectedGroup.name;
        this.editGroupModel.roleId = this.selectedGroup.role?.id || "";
        this.editGroupModel.moduleId = this.selectedGroup.module?.id || "";
        this.editGroupModel.users = this.selectedGroup.users;
        this.editGroupModel.regions = this.selectedGroup.regions ?? [];
        this.editGroupModel.isNotify = this.selectedGroup.isNotify;
        this.populatePermissions(this.editGroupModel, true);
    };

    editGroup = async (callback: () => void) => {
        if (this.progressFlags.editGroupRequest) {
            return;
        }

        this.progressFlags.editGroupRequest = true;
        this.clearErrors();

        const request = this.generateEditRequest();

        try {
            const response = this.identityStore.isSystemSpace
                ? await services.Groups.editSystemGroup(request as userManagementApi.EditSystemGroupRequest)
                : await services.Groups.editCustomerGroup(request as userManagementApi.EditCustomerGroupRequest);

            if (!_.inRange(response.status, 200, 300)) {
                handleErrors(response, this.setErrors);
                return;
            }

            this.selectedGroup = response.data;
            const groupListItem = this.groups.find((x) => x.id === this.selectedGroup.id);

            if (groupListItem) {
                groupListItem.name = this.selectedGroup.name;
                groupListItem.role = this.selectedGroup.role;
            }

            callback();
        } finally {
            this.progressFlags.editGroupRequest = false;
        }
    };

    generateEditRequest = () => {
        const [model, group] = [this.editGroupModel, this.selectedGroup];

        const fields = [
            { name: "name", add: !group.readonlyName && model.name !== group.name },
            { name: "isNotify", add: model.isNotify !== group.isNotify },
            { name: "roleId", add: !group.readonlyPermissions && model.roleId !== (group.role?.id || "") },
        ];

        const fieldsToEdit = fields.filter((x) => x.add).map((x) => x.name);

        const request: SharedProperties<
            userManagementApi.EditSystemGroupRequest,
            userManagementApi.EditCustomerGroupRequest
        > = {
            fieldsToEdit,
            groupId: group.id,
            name: model.name,
            roleId: model.roleId || undefined,
            addedUsers: model.users.map((x) => x.id),
            removedUsers: group.users.map((x) => x.id).filter((x) => !model.users.map((y) => y.id).includes(x)),
            addedRegions: model.regions,
            isNotify: model.isNotify,
            permissionsToCheckAndIgnoreRole: group.readonlyPermissions
                ? undefined
                : model.permissions
                      .filter((x) => x.overrideType === userManagementModels.PermissionOverrideType.CheckAndIgnoreRole)
                      .map((x) => x.id),
            permissionsToUncheckAndIgnoreRole: group.readonlyPermissions
                ? undefined
                : model.permissions
                      .filter(
                          (x) => x.overrideType === userManagementModels.PermissionOverrideType.UncheckAndIgnoreRole
                      )
                      .map((x) => x.id),
            permissionsToManageByRole: group.readonlyPermissions
                ? undefined
                : model.permissions
                      .filter((x) => x.overrideType === userManagementModels.PermissionOverrideType.None)
                      .map((x) => x.id),
        };

        if (this.identityStore.isSystemSpace) {
            const editSystemGroupRequest: userManagementApi.EditSystemGroupRequest = request;
            return editSystemGroupRequest;
        } else {
            const editCustomerGroupRequest: userManagementApi.EditCustomerGroupRequest = {
                ...request,
                customerId: this.identityStore.currentCustomer.id,
            };
            return editCustomerGroupRequest;
        }
    };

    clearFormState = () => {
        this.editGroupModel = {
            name: "",
            roleId: "",
            moduleId: "",
            users: [],
            permissions: [],
            isNotify: false,
            regions: [],
            allCountriesAndRegions: false,
        };

        this.addGroupModel = {
            name: "",
            roleId: "",
            moduleId: "",
            users: [],
            permissions: [],
            isNotify: false,
            allCountriesAndRegions: false,
            regions: [],
        };

        this.selectedGroup = {
            id: "",
            name: "",
            role: null,
            permissions: [],
            status: userManagementModels.GroupStatus.Active,
            users: [],
            module: null,
            readonlyName: false,
            readonlyPermissions: false,
            readonlyStatus: false,
            isDeleted: false,
            isNotify: false,
            regions: [],
        };

        this.permissionsForSelection = [];
        this.rolesForSelection = [];
        this.usersForSelection = [];
        this.modulesForSelection = [];
        this.roleTouched = false;

        this.clearErrors();
    };
}
