import { CircularProgress } from "@material-ui/core";
import { DatePicker } from "antd";
import { PickerDateProps } from "antd/es/date-picker/generatePicker";
import clsx from "clsx";
import { Dayjs } from "dayjs";
import { LynxIcon } from "icons/LynxIcon";
import { ReactNode } from "react";
import LynxTypography from "../LynxTypography/LynxTypography";
import { lynxDateTimePickerStyles } from "./LynxDateTimePickerStyles";

export interface LynxDateTimePickerProps extends PickerDateProps<Dayjs> {
    loading?: boolean;
    label?: string | ReactNode;
    labelClassName?: string;
}

export const LynxDateTimePicker = (props: LynxDateTimePickerProps) => {
    const classes = lynxDateTimePickerStyles();

    const { className, loading, label, labelClassName, ...otherProps } = props;

    const labelClasses = clsx(classes.label, props.labelClassName);

    const combinedClassName = clsx(classes.container, className);

    return (
        <>
            {!!label && (
                <LynxTypography variant="body-s" color={"neutral400"} className={labelClasses}>
                    {label}
                </LynxTypography>
            )}

            <DatePicker
                popupClassName={classes.popup}
                className={combinedClassName}
                suffixIcon={loading ? <CircularProgress size="1rem" /> : <LynxIcon name="calendar" />}
                clearIcon={<></>}
                {...otherProps}
            />
        </>
    );
};

