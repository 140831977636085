import { apiRoutes } from "api/apiRoutes";
import { CustomerLevelRequest } from "api/models/sharedModels/CustomerLevelRequest";
import { ErrorResponse, ErrorResponseWithPayload } from "api/models/sharedModels/ErrorResponse";
import * as userManagementApi from "api/models/userManagement/userManagementApi";
import { torAxios, torAxiosIgnoreErrors } from "api/serviceConfig";
import { AxiosRequestConfig } from "axios";
import { Page } from "models/shared/Page";
import { TokenVerificationStatus } from "models/userManagement/userManagementModels";

export class UsersService {
    initializeForgotPasswordFlow = async (email: string) => {
        const url = apiRoutes.users.forgotPassword;
        const response = await torAxios.post(url, { email });
        return response;
    };

    verifyRecoveryToken = async (token: string) => {
        const url = apiRoutes.users.verifyRecoveryToken;
        const requestConfig = {
            headers: { "Content-Type": "application/json" },
            transformResponse: (data: any) => {
                try {
                    data = JSON.parse(data);
                    const error = data as ErrorResponse;

                    if (error && error.statusCode === 400) {
                        error.notDisplayPopup = true;
                    }
                } catch (error) {}

                return data;
            },
        };

        const response = await torAxios.post<userManagementApi.RecoveryTokenResponse>(url, token, requestConfig);
        return response;
    };

    resetPassword = async (token: string, password: string) => {
        const url = apiRoutes.users.resetPassword;
        const requestConfig = {
            transformResponse: (data: any) => {
                try {
                    data = JSON.parse(data);
                    const error = data as ErrorResponseWithPayload<userManagementApi.RecoveryTokenVerificationResponse>;

                    if (
                        error &&
                        error.statusCode === 400 &&
                        (error.payload?.verificationStatus === TokenVerificationStatus.Invalid ||
                            error.payload?.verificationStatus === TokenVerificationStatus.Expired)
                    ) {
                        error.notDisplayPopup = true;
                    }
                } catch (error) {}

                return data;
            },
        };
        const response = await torAxios.post(url, { token, password }, requestConfig);
        return response;
    };

    getCurrentUserDetails = async (request?: userManagementApi.GetCurrentUserDetailsRequest) => {
        const url = apiRoutes.users.getCurrentUserDetails;
        const response = await torAxios.get<userManagementApi.CurrentUserDetailsResponse>(url);
        return response;
    };

    listSystemUsers = async (request: userManagementApi.ListSystemUsersRequest) => {
        const url = apiRoutes.users.listSystemUsers;

        const requestConfig: AxiosRequestConfig = {
            params: {
                pageNumber: request.pageNumber,
                pageSize: request.pageSize,
                users: request.users,
                fromGroups: request.fromGroups,
                includeGroups: request.includeGroups,
            },
        };

        const response = await torAxios.get<Page<userManagementApi.SystemUserListItemResponse>>(url, requestConfig);
        return response;
    };

    getSystemUserDetails = async (request: userManagementApi.GetSystemUserDetailsRequest) => {
        const url = apiRoutes.users.getSystemUserDetails(request.userId);
        const response = await torAxios.get<userManagementApi.SystemUserDetailsResponse>(url);
        return response;
    };

    createSystemUser = async (request: userManagementApi.CreateSystemUserRequest) => {
        const url = apiRoutes.users.createSystemUser;

        const body = {
            id: request.id,
            firstName: request.firstName,
            lastName: request.lastName,
            title: request.title,
            withPassword: request.withPassword,
            password: request.password,
            groups: request.groups,
            customers: request.customers,
        };

        const response = await torAxiosIgnoreErrors.post<userManagementApi.SystemUserDetailsResponse>(url, body);
        return response;
    };

    editSystemUser = async (request: userManagementApi.EditSystemUserRequest) => {
        const url = apiRoutes.users.editSystemUser(request.userId);

        const body = {
            fieldsToEdit: request.fieldsToEdit,
            status: request.status,
            firstName: request.firstName,
            lastName: request.lastName,
            title: request.title,
            addedToGroups: request.addedToGroups,
            removedFromGroups: request.removedFromGroups,
            addedToCustomers: request.addedToCustomers,
            removedFromCustomers: request.removedFromCustomers,
        };

        const response = await torAxiosIgnoreErrors.patch<userManagementApi.SystemUserDetailsResponse>(url, body);
        return response;
    };

    listCustomerUsers = async (request: userManagementApi.ListCustomerUsersRequest) => {
        const url = apiRoutes.users.listCustomerUsers(request.customerId);

        const requestConfig: AxiosRequestConfig = {
            params: {
                pageNumber: request.pageNumber,
                pageSize: request.pageSize,
                users: request.users,
                fromGroups: request.fromGroups,
                includeGroups: request.includeGroups,
                systemUsersOnly: request.systemUsersOnly,
                searchValue: request.searchValue,
            },
        };

        const response = await torAxios.get<Page<userManagementApi.CustomerUserListItemResponse>>(url, requestConfig);
        return response;
    };

    getCustomerUserDetails = async (request: userManagementApi.GetCustomerUserDetailsRequest) => {
        const url = apiRoutes.users.getCustomerUserDetails(request.customerId, request.userId);
        const response = await torAxios.get<userManagementApi.CustomerUserDetailsResponse>(url);
        return response;
    };

    createCustomerUser = async (request: userManagementApi.CreateCustomerUserRequest) => {
        const url = apiRoutes.users.createCustomerUser(request.customerId);

        const body = {
            id: request.id,
            firstName: request.firstName,
            lastName: request.lastName,
            title: request.title,
            withPassword: request.withPassword,
            password: request.password,
            groups: request.groups,
        };

        const response = await torAxiosIgnoreErrors.post<userManagementApi.CustomerUserDetailsResponse>(url, body);
        return response;
    };

    editCustomerUser = async (request: userManagementApi.EditCustomerUserRequest) => {
        const url = apiRoutes.users.editCustomerUser(request.customerId, request.userId);

        const body = {
            fieldsToEdit: request.fieldsToEdit,
            status: request.status,
            firstName: request.firstName,
            lastName: request.lastName,
            title: request.title,
            addedToGroups: request.addedToGroups,
            removedFromGroups: request.removedFromGroups,
        };

        const response = await torAxiosIgnoreErrors.patch<userManagementApi.CustomerUserDetailsResponse>(url, body);
        return response;
    };

    getCustomerUsersListCsv = async (request: CustomerLevelRequest) => {
        const url = apiRoutes.users.getCustomerUsersListCsv(request.customerId);

        const requestConfig: AxiosRequestConfig = { responseType: "blob" };

        return await torAxios.get(url, requestConfig);
    };

    getSystemUsersListCsv = async () => {
        const url = apiRoutes.users.getSystemUsersListCsv();

        const requestConfig: AxiosRequestConfig = { responseType: "blob" };

        return await torAxios.get(url, requestConfig);
    };

    logAuditEntry = async (email: string, oktaAuthResult: string) => {
        const url = apiRoutes.audit.logAuditEntry();

        const body = {
            email: email,
            oktaAuthResult: oktaAuthResult,
        };

        const response = await torAxios.post<string>(url, body);
        return response;
    };

    approveUserTerms = async () => {
        const url = apiRoutes.users.approveAgreementTerms();

        const response = await torAxios.patch(url);
        return response;
    };

    getUserGuidePdf = async (userGuideName: string, customerId: string) => {
        const url = apiRoutes.users.getUserGuidePdf(userGuideName);
        const requestConfig: AxiosRequestConfig = {
            params: {
                customerId: customerId,
            },
        };
        const response = await torAxios.get(url, requestConfig);
        return response;
    };
}
