import { Checkbox, FormControlLabel } from "@material-ui/core";
import clsx from "clsx";
import { LynxIcon } from "icons/LynxIcon";
import { ReactNode } from "react";
import LynxTypography from "../LynxTypography/LynxTypography";
import { LynxCheckBoxProps } from "./LynxCheckBoxProps";
import { lynxCheckBoxStyles } from "./LynxCheckBoxStyles";

export const LynxCheckBox = (props: LynxCheckBoxProps) => {
    const classes = lynxCheckBoxStyles();
    const { className, labelClassName, iconClassName, label, ...otherProps } = props;

    const icon = (children: ReactNode) => <span className={clsx(classes.icon, iconClassName)}>{children}</span>;

    return (
        <FormControlLabel
            className={clsx(classes.root, labelClassName)}
            control={
                <Checkbox
                    disableRipple
                    className={clsx(classes.checkboxStyle, className)}
                    icon={<span className={classes.defaultIcon} />}
                    checkedIcon={icon(<LynxIcon name="checkSmall" />)}
                    indeterminateIcon={icon(<LynxIcon name="minusSmall" />)}
                    disabled={props.disabled}
                    {...otherProps}
                />
            }
            label={
                !!label && (
                    <LynxTypography variant="body-medium" className={classes.label}>
                        {label}
                    </LynxTypography>
                )
            }
        />
    );
};

