import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const auditEntryPayloadRendererStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            flexDirection: "column",
            gap: "1.5rem",
        },

        tableRow: {
            "&:nth-child(odd)": {
                backgroundColor: theme.colors.primary.blue75,
            },
        },

        tableCell: {
            padding: "1rem",
            verticalAlign: "top",

            "&:first-child": {
                paddingLeft: "0.5rem",
            },

            "&:last-child": {
                paddingRight: "0.5rem",
            },
        },

        tableHeaderCell: {
            paddingTop: "0rem",
            paddingBottom: "0.5rem",
            whiteSpace: "nowrap",
            lineHeight: "1rem",
        },

        contentContainer: {
            display: "flex",
            flexDirection: "column",
            gap: "2rem",
            flexGrow: 1,
        },

        collapsableContentContainer: {
            display: "grid",
            gridTemplateColumns: "auto 1fr",
            gridTemplateRows: "auto auto",
            gap: "0rem 0.5rem",
        },

        titleContainer: {
            display: "flex",
            alignItems: "center",
        },

        verticalLineContainer: {
            display: "flex",
            justifyContent: "center",

            "& > div": {
                width: "1px",
                backgroundColor: theme.colors.primary.blue100,
            },
        },

        collapseButton: {
            padding: "0.25rem",
            border: `1px solid ${theme.colors.primary.blue200}`,
        },

        childrenContainer: {
            marginTop: "1.5rem",
        },
    })
);
