import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import GeneralErrorPage from "components/ErrorComponents/GeneralErrorPage";
import { LynxButton } from "components/LynxComponents/LynxButton/LynxButton";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { FullScreenLoadingIndicator } from "components/ReusableComponents/LoadingIndicator/FullScreenLoadingIndicator";
import ThorPagination from "components/ReusableComponents/ThorPagination/ThorPagination";
import { ModuleChip } from "components/userManagement/ModuleChip/ModuleChip";
import { observer } from "mobx-react";
import { actions } from "models/userManagement/actions";
import { GroupListItem, GroupStatus, MarvelPermissionStatus } from "models/userManagement/userManagementModels";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { useStore } from "store/StoreConfigs";
import UserActions, { UserActionOption } from "../UserActions";
import { userManagementStyles } from "../tabs/userManagementStyles";

export const GroupList = observer(() => {
    const classes = userManagementStyles();
    const { groupStore, identityStore, permissionsStore, commonStore } = useStore();

    const navigate = useNavigate();

    const editPermission = identityStore.isSystemSpace
        ? permissionsStore.getPermissionResult(actions.system.groups.manage)
        : permissionsStore.getPermissionResult(actions.customer.groups.manage, identityStore.currentCustomer.id);

    const viewPermission = identityStore.isSystemSpace
        ? permissionsStore.getPermissionResult(actions.system.groups.view)
        : permissionsStore.getPermissionResult(actions.customer.groups.view, identityStore.currentCustomer.id);

    useEffect(() => {
        groupStore.loadGroups();

        return () => commonStore.setShowGeneralErrorPageToFalse();
    }, [groupStore.currentPage, groupStore.pageSize]);

    const deletedGroupRow = () => {
        return (
            <TableCell colSpan={identityStore.isSystemSpace ? 4 : 5}>
                <LynxTypography>Deleted</LynxTypography>
            </TableCell>
        );
    };

    const getGroupActions = (group: GroupListItem) => {
        const actions: UserActionOption[] = [
            {
                optionName: "View / Edit Details",
                action: () => navigate(`../${group.id}`),
            },
        ];

        if (editPermission.status === MarvelPermissionStatus.Allow && !group.readonlyStatus) {
            actions.push({
                optionName: group.status === "Active" ? "Deactivate" : "Activate",
                action: () =>
                    groupStore.changeGroupStatus(
                        group.id,
                        group.status === GroupStatus.Active ? GroupStatus.Deactivated : GroupStatus.Active
                    ),
            });
        }

        if (viewPermission.status === MarvelPermissionStatus.Allow) {
            actions.push({
                optionName: "Audit Trail",
                action: () => navigate(`/userManagement/groups/${group.id}/audit`),
                withImmediateAction: true,
            });
        }

        return actions;
    };

    const groupRow = (group: GroupListItem) => {
        return (
            <>
                {/* <TableCell>
                    <LynxTypography className={classes.bolderText}>{group.id}</LynxTypography>
                </TableCell> */}
                <TableCell>
                    <LynxTypography className={classes.bolderText}>{group.name}</LynxTypography>
                </TableCell>
                {!identityStore.isSystemSpace && (
                    <TableCell>
                        <ModuleChip text={group.module?.name} moduleId={group.module?.id} />
                    </TableCell>
                )}
                <TableCell>
                    <LynxTypography className={classes.groupRoleName}>{group.role?.name}</LynxTypography>
                </TableCell>
                <TableCell>
                    <LynxTypography className={classes.tableText}>{group.status}</LynxTypography>
                </TableCell>
                <TableCell>
                    <UserActions options={getGroupActions(group)} />
                </TableCell>
            </>
        );
    };

    return (
        <div>
            <LynxButton
                size="medium"
                className={classes.addButton}
                onClick={() => navigate("../create")}
                disabled={editPermission.status !== MarvelPermissionStatus.Allow}
                loading={editPermission.status === MarvelPermissionStatus.Loading}
            >
                Add New User Group
            </LynxButton>

            {groupStore.progressFlags.loadingGroups ? (
                <FullScreenLoadingIndicator />
            ) : commonStore.showGeneralErrorPage ? (
                <GeneralErrorPage />
            ) : (
                <>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow style={{ borderBottom: "none" }}>
                                {/* <TableCell className={classes.headerCell}>Id</TableCell> */}
                                <TableCell className={classes.headerCell}>Name</TableCell>
                                {!identityStore.isSystemSpace && (
                                    <TableCell className={classes.headerCell}>Module</TableCell>
                                )}
                                <TableCell className={classes.headerCell}>Role</TableCell>
                                <TableCell className={classes.headerCell}>Status</TableCell>
                                <TableCell className={classes.headerCell} />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {groupStore.groups.map((group) => (
                                <TableRow key={group.id} className={classes.tableRow}>
                                    {/* {group.isDeleted ? deletedGroupRow() : groupRow(group)} */}
                                    {groupRow(group)}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>

                    <ThorPagination
                        page={groupStore.currentPage}
                        pages={groupStore.totalPages}
                        onPageChange={groupStore.movePage}
                        isLastPage={groupStore.isLastPage}
                        setPage={groupStore.setPage}
                        localStorageItemName={"groupsPageSize"}
                        setPageSize={groupStore.setPageSize}
                    />
                </>
            )}
        </div>
    );
});
