import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import clsx from "clsx";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import Strong from "components/LynxComponents/LynxTypography/Strong";
import { LynxRecommendationIcon } from "components/LynxRecommendationIcon";
import { commonConstants } from "lynxConstants";
import { getProductFullName } from "helpers/getProductFullName";
import { getAssessmentDecisionText } from "helpers/getRecommendationText";
import { ReactNode } from "react";
import { eventDescriptionStyles } from "./EventDescriptionStyles";
import { EventTemperatureRangeDescription } from "./EventTemperatureRangeDescription";
import { EventTotalExcursionDurationDescription } from "./EventTotalExcursionDurationDescription";
import { InnerEventDescriptionProps } from "./InnerEventDescriptionProps";

export const InnerEventDescription = (props: InnerEventDescriptionProps) => {
    const classes = eventDescriptionStyles();

    const joinValues = (data: string[]) =>
        data?.length > 0 ? data.map((x) => x || commonConstants.emptyValue).join(", ") : commonConstants.emptyValue;

    const tableColumnHeader = (label: ReactNode) => (
        <TableCell className={classes.headerCell}>
            <LynxTypography variant="body-s" color="neutral400">
                {label}
            </LynxTypography>
        </TableCell>
    );

    const showRecommendation = props.assessments.every((x) => x.recommendedDecision !== undefined);

    const getEventProductsDescription = () => (
        <Table className={classes.table}>
            <TableHead>
                <TableRow>
                    {tableColumnHeader("Product")}
                    {tableColumnHeader(<>Batch&nbsp;ID</>)}
                    {tableColumnHeader("Quantity")}
                    {showRecommendation && tableColumnHeader("Automated Assessment")}
                </TableRow>
            </TableHead>
            <TableBody>
                {props.assessments.map((x) => {
                    return (
                        <TableRow key={x.id}>
                            <TableCell className={classes.bodyCell}>
                                <LynxTypography variant="body" color="neutral600">
                                    {getProductFullName(
                                        x.productName,
                                        x.productDosage,
                                        x.productDoseFormName,
                                        x.productDosageUomName
                                    )}
                                </LynxTypography>
                            </TableCell>
                            <TableCell className={classes.bodyCell}>
                                <LynxTypography variant="body" color="neutral600">
                                    {x.batchNumber}
                                </LynxTypography>
                            </TableCell>
                            <TableCell className={classes.bodyCell}>
                                <LynxTypography variant="body" color="neutral600">
                                    {x.quantity === null ? commonConstants.emptyValue : Number(x.quantity)}
                                </LynxTypography>
                            </TableCell>
                            {showRecommendation && (
                                <TableCell className={clsx(classes.bodyCell, classes.automatedAssessmentColumn)}>
                                    <LynxRecommendationIcon recomendation={x.recommendedDecision!} />
                                    <LynxTypography>{getAssessmentDecisionText(x.recommendedDecision!)}</LynxTypography>
                                </TableCell>
                            )}
                        </TableRow>
                    );
                })}
            </TableBody>
        </Table>
    );

    return (
        <div className={classes.innerDescriptionContainer}>
            <div>
                <EventTemperatureRangeDescription
                    isSiteEvent={props.isSiteEvent}
                    minTemperature={props.minTemperature}
                    maxTemperature={props.maxTemperature}
                    deliveryNumber={props.deliveryNumber}
                />
                {props.manualExcursionDeviceIds && (
                    <LynxTypography>TRDs: {props.manualExcursionDeviceIds}.</LynxTypography>
                )}
                <EventTotalExcursionDurationDescription
                    totalDuration={props.totalExcursionDuration}
                    totalHighDuration={props.totalHighExcursionDuration}
                    totalLowDuration={props.totalLowExcursionDuration}
                />
            </div>
            {getEventProductsDescription()}
            {!props.isSiteEvent && (
                <div>
                    <LynxTypography>
                        Purchase Order(s): <Strong>{props.orderNumbers || commonConstants.emptyValue}</Strong>
                    </LynxTypography>
                    {props.handlingUnits.length > 0 && (
                        <LynxTypography>
                            Handling Unit(s): <Strong>{joinValues(props.handlingUnits)}</Strong>
                        </LynxTypography>
                    )}
                </div>
            )}
        </div>
    );
};
