import { LynxRedirectLinkProps } from "./LynxRedirectLinkProps";
import { Link } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import ContentLoader from "react-content-loader";
import LynxTypography from "../LynxTypography/LynxTypography";

export const LynxRedirectLink = (props: LynxRedirectLinkProps) => {
    const {
        className,
        disabled,
        loading,
        loaderWidth,
        loaderHeight,
        to,
        variant = "body-l",
        color,
        typographyComponent,
        ...otherProps
    } = props;

    const [width, height] = [loaderWidth || 100, loaderHeight || 20];

    if (disabled) {
        return (
            <LynxTypography
                variant="body-medium"
                color={"neutral500"}
                className={className}
                component={typographyComponent}
            />
        );
    } else if (loading) {
        return (
            <ContentLoader width={width} height={height}>
                <rect x="0" y="0" rx="2" ry="2" width={width} height={height} />
            </ContentLoader>
        );
    } else {
        return (
            <Link component={RouterLink} to={to} {...otherProps} className={className}>
                <LynxTypography
                    variant={variant}
                    color={disabled ? "neutral500" : "blue500"}
                    component={typographyComponent}
                >
                    {props.children}
                </LynxTypography>
            </Link>
        );
    }
};
