import { createStyles, makeStyles } from "@material-ui/core/styles";

export const lynxBackButtonStyles = makeStyles(() =>
    createStyles({
        button: {
            width: "fit-content",
            marginBottom: "0.25rem",
        },
    })
);
