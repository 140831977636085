import { Grid } from "@material-ui/core";
import { LynxButton } from "components/LynxComponents/LynxButton/LynxButton";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { LynxIcon } from "icons/LynxIcon";
import { observer } from "mobx-react";
import { FilterBubblesProps } from "./FilterBubblesProps";
import { filterBubblesStyles } from "./FilterBubblesStyle";

export default observer(function FilterBubbles({ items, resetFiltersFunc }: FilterBubblesProps) {
    const classes = filterBubblesStyles();

    const removeFromFilters = (
        valueForRemove: string,
        selectedValues: string[],
        changeFunction: (newValues: string[]) => void
    ) => {
        const result = selectedValues.filter((x) => x !== valueForRemove);
        changeFunction(result);
    };

    const getDisplayValue = (selectedItems: string[], value: string) => {
        const item = selectedItems.find((x) => x === value);
        return item ? JSON.parse(item).itemDisplayName : "";
    };

    return (
        <Grid container alignItems="center">
            {items.map((filteredObject) =>
                filteredObject.selectedValues.map((value) => (
                    <Grid item className={classes.container} key={value}>
                        <Grid container alignItems="baseline" spacing={1}>
                            <Grid item>
                                <LynxTypography variant="body-s" color="neutral500">
                                    {filteredObject.filterName}: {getDisplayValue(filteredObject.selectedValues, value)}
                                </LynxTypography>
                            </Grid>
                            <Grid item>
                                <LynxButton
                                    variant="icon"
                                    onClick={() => {
                                        removeFromFilters(
                                            value,
                                            filteredObject.selectedValues,
                                            filteredObject.changeFunction
                                        );
                                    }}
                                >
                                    <LynxIcon name="crossSmall" />
                                </LynxButton>
                            </Grid>
                        </Grid>
                    </Grid>
                ))
            )}
            <Grid item>
                {items.some((x) => x.selectedValues.length > 0) && (
                    <LynxButton variant="tertiary" onClick={resetFiltersFunc} className={classes.clearButton}>
                        Clear Filters
                    </LynxButton>
                )}
            </Grid>
        </Grid>
    );
});

