import { ErrorResponse } from "api/models/sharedModels/ErrorResponse";
import { ValidationProblemDetailsResponse } from "api/models/sharedModels/ValidationProblemDetailsResponse";
import { AxiosResponse } from "axios";

type OmitNever<T extends Record<string, unknown>> = {
    [K in keyof T as T[K] extends never ? never : K]: T[K];
};

export type SharedProperties<A, B> = OmitNever<Pick<A & B, keyof A & keyof B>>;

export const isGenericApiError = (
    error: unknown
): error is AxiosResponse<ErrorResponse> & { response: AxiosResponse<ErrorResponse> } => {
    return !!(error as AxiosResponse<ErrorResponse>).data?.errorMessage;
};

export const isValidationProblemDetailsError = (
    error: unknown
): error is AxiosResponse<ValidationProblemDetailsResponse> & {
    response: AxiosResponse<ValidationProblemDetailsResponse>;
} => {
    return !!(error as AxiosResponse<ValidationProblemDetailsResponse>).data?.traceId;
};

export type WithRequired<T, K extends keyof T> = T & { [P in K]-?: T[P] };

export type JSONObject = string | number | boolean | null | { [key: string]: JSONObject } | JSONObject[];

type ImmutablePrimitive = undefined | null | boolean | string | number | Function;

export type Immutable<T> = {
    readonly [P in keyof T]: T[P] extends ImmutablePrimitive ? T[P] : Immutable<T[P]>;
};
