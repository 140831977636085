import { services } from "api/serviceConfig";
import { FormikErrors } from "formik";
import _ from "lodash";
import { makeAutoObservable } from "mobx";
import * as commonApi from "api/models/commonApi";
import * as commonModels from "models/commonModels";
import { validationHelpers } from "validation/helper-functions";
import { ErrorResponse } from "api/models/sharedModels/ErrorResponse";

export default class CommonStore {
    constructor() {
        makeAutoObservable(this);
    }

    progressFlags = {
        loadingComments: false,
        loadingNextSetOfComments: false,
    };

    userLoginError = localStorage.getItem("userLoginError") ?? "";
    popupsCount = 0;
    scrollWatcherTarget: HTMLDivElement | null = null;
    showGeneralErrorPage: boolean = false;

    incrementPopupsCount = () => this.popupsCount++;
    decrementPopupsCount = () => this.popupsCount--;

    clearPopupsCount = () => (this.popupsCount = 0);

    setLoginError = (error: string) => {
        this.userLoginError = error;
    };

    setScrollWatcherTarget = (value: HTMLDivElement | null) => {
        this.scrollWatcherTarget = value;
    };

    setShowGeneralErrorPageToFalse = () => {
        this.showGeneralErrorPage = false;
    };

    setShowGeneralErrorPageToTrue = () => {
        this.showGeneralErrorPage = true;
    };

    // comments
    comments: commonModels.CommentModel[] = [];
    commentErrors: FormikErrors<commonModels.CommentFormModal> | null = null;
    commentsTotalCount: number = 0;
    commentFirstSetLoadingCount: number = 0;

    incrementCommentLoadingCountToForceReload = () => this.commentFirstSetLoadingCount++;

    resetCommentsData = () => {
        this.comments = [];
        this.commentErrors = null;
        this.commentsTotalCount = 0;
        this.commentFirstSetLoadingCount = 0;
    };

    getComments = async (request: commonApi.GetCommentsRequest, isFirstLoad: boolean) => {
        if (this.progressFlags.loadingComments || this.progressFlags.loadingNextSetOfComments) {
            return;
        }

        isFirstLoad
            ? (this.progressFlags.loadingComments = true)
            : (this.progressFlags.loadingNextSetOfComments = true);

        try {
            const response = await services.Common.getComments(request);

            if (!_.inRange(response.status, 200, 300)) return;

            this.comments = isFirstLoad ? response.data.items : _.concat(this.comments, response.data.items);
            this.commentsTotalCount = response.data.totalCount;
        } finally {
            isFirstLoad
                ? (this.progressFlags.loadingComments = false)
                : (this.progressFlags.loadingNextSetOfComments = false);
        }
    };

    createComment = async (request: commonApi.CreateCommentRequest) => {
        if (this.progressFlags.loadingComments) {
            return;
        }

        this.progressFlags.loadingComments = true;
        let response;

        const trimmed: commonApi.CreateCommentRequest = {
            ...request,
            comment: request.comment.trim(),
        };

        try {
            response = await services.Common.createComment(trimmed);

            if (!_.inRange(response.status, 200, 300)) return;

            this.commentErrors = null;
            this.commentFirstSetLoadingCount++;
        } finally {
            this.progressFlags.loadingComments = false;

            if (!!response && response.status === 400) {
                this.commentErrors = validationHelpers.generateFormikErrorsFromApi<commonModels.CommentFormModal>(
                    (response?.data as unknown as ErrorResponse)?.validationErrors ?? []
                );
            }
        }
    };
}
