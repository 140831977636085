import { Box, FormControl } from "@material-ui/core";
import clsx from "clsx";
import { LynxInput } from "components/LynxComponents/LynxInput/LynxInput";
import { LynxRedirectLink } from "components/LynxComponents/LynxRedirectLink/LynxRedirectLink";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { observer } from "mobx-react";
import { ChangeEvent, useState } from "react";
import routes from "routes";
import { LynxModal } from "../LynxModal/LynxModal";
import { AuthModalProps } from "./AuthModalProps";
import { authModalStyles } from "./AuthModalStyles";

export const AuthModal = observer((props: AuthModalProps) => {
    const classes = authModalStyles();
    const [email, setEmail] = useState(localStorage.getItem("email") || "");
    const [password, setPassword] = useState("");
    const [passwordHasError, setPasswordHasError] = useState(false);
    const [emailHasError, setEmailHasError] = useState(false);
    const emailRegexp =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

    const handleEmailChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
        setEmailHasError(false);
        const emailToLowerCase = value.toLocaleLowerCase();
        setEmail(emailToLowerCase);
    };

    const handlePasswordChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
        setPasswordHasError(false);
        setPassword(value);
    };

    const handleCancelClick = () => {
        props.onCancel();
        setPassword("");
    };

    const handleBackClick = () => {
        props.onBack();
        setPassword("");
    };

    const isFormValid = (): boolean => {
        if ((password || password !== "") && emailRegexp.test(email)) {
            setPasswordHasError(false);
            setEmailHasError(false);
            return true;
        }

        if (!password || password === "") {
            setPasswordHasError(true);
        }

        if (!emailRegexp.test(email)) {
            setEmailHasError(true);
        }

        return false;
    };

    const onSubmit = () => {
        if (!isFormValid()) {
            return;
        }

        props.onSubmit({ login: email, password });
    };

    return (
        <LynxModal
            open={props.isOpen}
            onConfirm={onSubmit}
            header="Re-authentication Required"
            title="In order to confirm this decision you must provide your login credentials."
            disabledCancel={props.disabled}
            disabledSubmit={props.disabled}
            primaryButtonTitle="Submit"
            secondaryButtonTitle="Cancel"
            backButtonTitle="Back"
            onClose={handleCancelClick}
            onBack={handleBackClick}
            loading={props.loading}
        >
            <FormControl
                error={emailHasError}
                variant="standard"
                className={clsx(classes.form)}
                onKeyDown={(e) => {
                    if (e.key === "Enter") {
                        onSubmit();
                    }
                }}
            >
                <LynxInput
                    size="large"
                    name="email"
                    value={email}
                    label="Email"
                    type="email"
                    onChange={handleEmailChange}
                    required={true}
                    error={emailHasError}
                    className={classes.input}
                />
                <LynxTypography
                    className={emailHasError ? classes.formErrorYes : classes.formErrorNo}
                    color="critical500"
                    variant="body-s"
                    id="email-error-text"
                >
                    Email is incorrect
                </LynxTypography>
                <br />
                <LynxInput
                    size="large"
                    name="password"
                    value={password}
                    label="Password"
                    onChange={handlePasswordChange}
                    passwordInput
                    required={true}
                    error={passwordHasError}
                    className={classes.input}
                />
                <LynxTypography
                    className={passwordHasError ? classes.formErrorYes : classes.formErrorNo}
                    id="password-error-text"
                    color="critical500"
                    variant="body-s"
                >
                    Password required
                </LynxTypography>
                <LynxRedirectLink
                    to={routes.forgotPassword}
                    className={classes.forgotPasswordLink}
                    variant="body-medium"
                >
                    Forgot your Password?
                </LynxRedirectLink>
                <br />
            </FormControl>

            <Box justifyContent={"start"} display={props.errorMessage ? "flex" : "none"}>
                <LynxTypography color="critical500" variant="body-s" paragraph>
                    {props.errorMessage}
                </LynxTypography>
            </Box>
        </LynxModal>
    );
});
