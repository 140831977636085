import { Grid } from "@material-ui/core";
import LynxAutocomplete from "components/LynxComponents/LynxAutocomplete/LynxAutocomplete";
import { observer } from "mobx-react";
import { EventStatus } from "models/thorEvents/eventModels";
import { ChangeEvent, useEffect, useState } from "react";
import { useStore } from "store/StoreConfigs";
import { assignEventMenuModalStyles } from "./AssignEventMenuModalStyles";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { Formik, Form, Field } from "formik";
import LynxInputForm from "components/ReusableForms/LynxInputForm";
import { SearchOption } from "components/ReusableForms/Props/LynxSearchFormProps";
import { assignEventCommentValidationSchema } from "validation/ValidationSchemas/EventDetailsSchemas/assignEventCommentValidationSchema";
import { LynxModal } from "components/ReusableComponents/LynxModal/LynxModal";

export interface AssignEventMenuModalProps {
    eventId: string;
    reviewerUserId: string | null;
    qaUserId: string | null;
    eventStatus: EventStatus;
}

export const AssignEventMenuModal = observer(({ eventId, eventStatus }: AssignEventMenuModalProps) => {
    const classes = assignEventMenuModalStyles();
    const { thorEventViewStore, userStore, identityStore } = useStore();
    const [userIdValue, setUserIdValue] = useState("");
    const [comment, setComment] = useState("");

    const getCustomerUsers = (value: string | undefined) => {
        userStore.loadCustomerUsers({
            pageNumber: 1,
            pageSize: 5,
            customerId: identityStore.currentCustomer.id,
            searchValue: value,
        });
    };

    useEffect(() => {
        getCustomerUsers(undefined);
    }, []);

    const selectBasedOnStatus = (forReviewer: any, forQA: any) =>
        eventStatus === EventStatus.New || eventStatus === EventStatus.PendingReview ? forReviewer : forQA;

    const handleChangeUser = (
        event: ChangeEvent<{}>,
        value: NonNullable<string | SearchOption> | (string | SearchOption)[]
    ) => {
        const option = value as SearchOption;
        setUserIdValue(option.id);
    };

    const loading =
        thorEventViewStore.progressFlags.assigningUserToEvent || userStore.progressFlags.loadingUsersForDropdown;

    const handleSubmit = () => {
        if (!userIdValue) {
            return;
        }

        thorEventViewStore.assignUserToEvent({
            userId: userIdValue,
            assignUserType: selectBasedOnStatus(1, 2),
            eventId: eventId,
            customerId: identityStore.currentCustomer.id,
            comment: comment,
        });
    };

    const onCancel = () => {
        thorEventViewStore.setAssignMenuOpen(false);
    };

    return (
        <LynxModal
            open={thorEventViewStore.isAssignMenuOpen}
            secondaryButtonTitle="Cancel"
            primaryButtonTitle="Assign"
            header="Assign Event"
            onConfirm={handleSubmit}
            onClose={onCancel}
            loading={loading}
            disabledCancel={loading}
            disabledSubmit={loading || !userIdValue}
            sidebar
        >
            <Grid item>
                <Formik
                    initialValues={{
                        content: "",
                    }}
                    validateOnBlur={false}
                    validateOnChange={true}
                    validationSchema={assignEventCommentValidationSchema()}
                    onSubmit={handleSubmit}
                >
                    <Form>
                        <LynxAutocomplete
                            className={classes.autocompleteContainer}
                            renderOptionsWithAvatars
                            options={userStore.customerUsers.map((x) => ({
                                id: x.id,
                                displayName: `${x.firstName} ${x.lastName}`,
                            }))}
                            search={getCustomerUsers}
                            renderInput={() => {
                                return <div></div>;
                            }}
                            label="Search by name"
                            loading={loading}
                            onChange={handleChangeUser}
                            placeholder="Search person to assign"
                            showArrowIcon={false}
                        />

                        <Grid item className={classes.commentContainer}>
                            <LynxTypography variant="body-s" color="neutral400">
                                Comment
                            </LynxTypography>
                            <Field
                                className={classes.textArea}
                                name="content"
                                as={LynxInputForm}
                                multiline
                                minRows={6}
                                placeholder="Leave your comment here..."
                                onChange={(e: any) => {
                                    setComment(e.target.value);
                                }}
                                value={comment}
                            />
                            <Grid item>
                                <LynxTypography variant="body-s" color="neutral400">
                                    Comments will be included in the email sent to the assignee and added to Comments on
                                    the event.
                                </LynxTypography>
                            </Grid>
                        </Grid>
                    </Form>
                </Formik>
            </Grid>
        </LynxModal>
    );
});
