import clsx from "clsx";
import { LynxButton } from "components/LynxComponents/LynxButton/LynxButton";
import { LynxRedirectLink } from "components/LynxComponents/LynxRedirectLink/LynxRedirectLink";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { IconFrame } from "components/PopupNotification/IconFrame";
import { LynxIcon } from "icons/LynxIcon";
import { observer } from "mobx-react";
import { NotificationType } from "models/shared/NotificationType";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import routes from "routes";
import { useStore } from "store/StoreConfigs";
import { loginStyles } from "../LoginStyles";

const PasswordSuccessfullyReset = observer(() => {
    const classes = loginStyles();
    const { userStore } = useStore();
    const navigate = useNavigate();

    // this page can only be visited programatically
    useEffect(() => {
        if (!userStore.passwordResetFlag) {
            navigate(routes.login, { replace: true });
        }

        return () => {
            userStore.setPasswordResetFlag(false);
            userStore.resetRecoveryTokenData();
        };
    }, []);

    return (
        <>
            <LynxTypography variant="h2" className={clsx(classes.title, classes.marginTopVeryLarge)}>
                New password
            </LynxTypography>

            <IconFrame type={NotificationType.SUCCESS}>
                <LynxIcon name="checkSmall" />
            </IconFrame>

            <LynxTypography className={classes.marginTopMiddle}>Password successfully changed</LynxTypography>

            <LynxTypography className={classes.marginBottomLarge}>
                Now you can sign in using new password
            </LynxTypography>

            <LynxRedirectLink to={routes.login}>
                <LynxButton size="large" className={classes.buttonFullWidth}>
                    Continue to sign in
                </LynxButton>
            </LynxRedirectLink>
        </>
    );
});

export default PasswordSuccessfullyReset;

