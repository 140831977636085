import { MenuList, MenuItem, Popover, Divider } from "@material-ui/core";
import clsx from "clsx";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { forceFileOpen } from "helpers/fileHelpers";
import { LynxIcon } from "icons/LynxIcon";
import { PromptTooltip } from "components/ReusableComponents/PromptTooltip/PromptTooltip";
import { observer } from "mobx-react";
import { SidebarPermissions } from "models/commonModels";
import { useEffect, useRef, useState } from "react";
import { useStore } from "store/StoreConfigs";
import { lynxSidebarStyles } from "./LynxSidebarStyles";

interface UserGuideMenuProps {
    permissions: SidebarPermissions;
}

export const UserGuideMenu = observer((props: UserGuideMenuProps) => {
    const classes = lynxSidebarStyles();
    const { permissions } = props;
    const userGuideItemEl = useRef(null);
    const { userStore } = useStore();
    const [open, setOpen] = useState(false);
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        const allowPermissions =
            permissions.excursionEvaluation || permissions.stabilityDataManagement || permissions.userManagement;
        const userGuideButtonDisabled = !allowPermissions || userStore.progressFlags.loadUserGuide;

        setDisabled(userGuideButtonDisabled);
    }, [permissions, userStore.progressFlags.loadUserGuide]);

    interface UserGuidesMenuItemsProps {
        name: string;
        link: string;
        disabled: boolean;
    }

    const userGuidesMenuItems: UserGuidesMenuItemsProps[] = [
        { name: "Events", link: "events", disabled: !permissions.excursionEvaluation },
        { name: "Stability Forms", link: "stabilityForms", disabled: !permissions.stabilityDataManagement },
        { name: "User Management", link: "userManagement", disabled: !permissions.userManagement },
    ];

    const getUserGuidePdf = async (userGuideName: string) => {
        if (disabled) {
            return;
        }

        const response = await userStore.getUserGuidePdf(userGuideName);

        if (response?.data) {
            forceFileOpen(response?.data);
        }

        setOpen(false);
    };

    return (
        <>
            <PromptTooltip placement="right" title="User Guide">
                <div
                    ref={userGuideItemEl}
                    tabIndex={disabled ? -1 : 0}
                    onClick={(e) => {
                        disabled && e.preventDefault();
                        setOpen(true);
                    }}
                    className={clsx(classes.navItem, {
                        [classes.navItemActive]: open && !disabled,
                        [classes.navItemDisabled]: disabled,
                        [classes.interactionsDisabled]: disabled,
                    })}
                >
                    <LynxIcon name="info" className={classes.icon} />
                    <LynxIcon
                        name="angleSmallDown"
                        className={clsx({
                            [classes.arrowRotated]: open,
                            [classes.arrowCollapsed]: true,
                        })}
                    />
                </div>
            </PromptTooltip>

            <Popover
                open={open}
                anchorEl={userGuideItemEl.current}
                onClose={() => setOpen(false)}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                disableScrollLock
                transformOrigin={{
                    vertical: "top",
                    horizontal: -5,
                }}
            >
                <MenuList className={clsx(classes.drawerPaper, classes.zeroPadding)}>
                    {userGuidesMenuItems.map((item) => {
                        return (
                            !item.disabled && (
                                <div>
                                    <MenuItem
                                        className={classes.navItem}
                                        onClick={() => getUserGuidePdf(item.link)}
                                        key={item.link}
                                    >
                                        <LynxTypography
                                            variant="body-sbold-s"
                                            color={disabled ? "neutral400" : "white"}
                                        >
                                            {item.name}
                                        </LynxTypography>
                                    </MenuItem>
                                    <Divider className={clsx(classes.divider)} />
                                </div>
                            )
                        );
                    })}
                </MenuList>
            </Popover>
        </>
    );
});
