import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const LynxAutocompleteStyles = makeStyles((theme: Theme) =>
    createStyles({
        avatarMarginRight: {
            marginRight: "0.5rem",
        },

        option: {
            display: "flex",
            justifyContent: "space-between",
            borderBottom: `1px ${theme.colors.primary.blue100} solid`,
            padding: "0.5rem 1rem",

            "&:last-child": {
                border: "none",
            },
        },

        listBox: {
            border: `1px ${theme.colors.primary.blue100} solid`,
            borderRadius: "4px",
        },
        arrowRightIcon: {
            fill: theme.colors.primary.blue500,
        },
    })
);

