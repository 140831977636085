import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const lynxDropdownStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            margin: "0rem 0.5rem",

            "&:hover": {
                borderRadius: theme.borderRadius,
                backgroundColor: theme.colors.primary.blue100,
            },

            "& .Mui-focused": {
                outline: `1px solid ${theme.colors.primary.blue400}`,
            },

            "& .MuiSelect-root": {
                borderRadius: theme.borderRadius,
                padding: "0.25rem",
                paddingRight: "1.25rem",
            },

            "& .MuiSelect-select:focus-visible, & .MuiSelect-select:focus": {
                backgroundColor: "inherit",
                border: "none",
                outline: "none",
            },

            "& .MuiSelect-menu": {
                minHeight: "initial",
            },

            "& .MuiSelect-icon": {
                top: "0.5rem",
                fill: theme.colors.neutrals.neutral600,
            },

            "& .MuiCircularProgress-root": {
                width: "1rem",
                height: "1rem",
            },

            "& .MuiSelect-iconOpen": {
                fill: theme.colors.neutrals.neutral600,
            },

            "& .Mui-disabled": {
                backgroundColor: theme.colors.neutrals.neutral200,
                color: theme.colors.neutrals.neutral400,
                fill: theme.colors.neutrals.neutral400,
                cursor: "not-allowed",
            },
        },

        menu: {
            maxHeight: "31.25rem",
            "& .MuiMenuItem-root": {
                "&.Mui-selected": {
                    backgroundColor: "inherit",
                },

                "&:hover": {
                    backgroundColor: theme.colors.primary.blue75,
                    color: theme.colors.primary.blue500,
                },
            },

            "& .MuiList-padding": {
                padding: "0rem",
            },

            "& .MuiMenu-paper": {
                border: `1px solid ${theme.colors.primary.blue100}`,
                borderTop: `none`,
            },
        },

        menuItem: {
            minWidth: "12rem",
            display: "flex",
            justifyContent: "space-between",
            borderTop: `1px solid ${theme.colors.primary.blue100}`,
            padding: "0.5rem 1rem",
        },

        checkboxLabel: {
            marginRight: "0rem",
            marginLeft: "0.5rem",
        },

        search: {
            border: `1px solid ${theme.colors.neutrals.neutral300}`,
        },

        loadingIndicatorContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "22rem",
            height: "15rem",
            padding: "0 0.5rem",
        },
        inputContainer: {
            padding: "0 0.5rem",
            margin: "0.5rem 0rem",
            width: "22rem",
        },

        error: {
            color: theme.colors.critical.critical500,
        },

        searchIcon: {
            cursor: "pointer",
            fill: theme.colors.neutrals.neutral300,
        },
    })
);

