import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const userViewStyles = makeStyles((theme: Theme) =>
    createStyles({
        tab: {
            minWidth: "72px",
            "&.MuiTab-root": {
                textTransform: "capitalize",
            },
        },

        tabList: {
            backgroundColor: "white",
            padding: "0 1.5rem",

            "& .MuiTab-textColorInherit.Mui-selected": {
                color: theme.colors.primary.blue500,
                opacity: 1,
            },
            "& .MuiTabs-indicator": {
                backgroundColor: theme.colors.primary.blue500,
            },

            "&.sticky": {
                padding: "0rem",
            },
        },

        tabContainer: {
            padding: "30px 30px",
        },

        smallIndicator: {
            marginTop: "30px",
            height: "20vh",
            width: "80vh",
            display: "flex",
            alignItems: "center",
        },
    })
);
