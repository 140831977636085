import { AnyObject } from "chart.js/types/basic";
import { trimLeadingZeros } from "helpers/trimLeadingZeros";
import { TestConfig } from "yup";

const regexForInteger = /^([0-9]+)$/;

const isNumber: TestConfig<string | undefined, AnyObject> = {
    name: "isNumber",
    message: "Must be a number",
    test: (value, ctx) => !isNaN(Number(value)),
};

const isNumberIfNotEmpty: TestConfig<string | undefined, AnyObject> = {
    name: "isNumberIfNotEmpty",
    message: "Must be a number",
    test: (value, ctx) => {
        if (!value) {
            return true;
        }

        return !isNaN(Number(value));
    },
};

const isPositiveNumberIfNotEmpty: TestConfig<string | null | undefined, AnyObject> = {
    name: "isNumber",
    message: "Must be a positive number",
    test: (value, ctx) => {
        if (!value) {
            return true;
        }

        return !isNaN(Number(value)) && Number(value) > 0;
    },
};

const lessThanOrEqualToForDurationInput: TestConfig<number | undefined, AnyObject> = {
    name: "LessThanOrEqualToForDurationInput",
    message: `Must be less than or equal to 100 000 hours`,
    test: (value, ctx) => (value ? value / 60 <= 100_000 : true),
};

const zerosNotAllowed: TestConfig<string | undefined, AnyObject> = {
    name: "CannotContainOnlyZeros",
    message: "Cannot contain only zeros.",
    test: (value, ctx) => trimLeadingZeros(value) !== "",
};

export const helperObjects = {
    isNumber,
    isNumberIfNotEmpty,
    isPositiveNumberIfNotEmpty,
    regexForInteger,
    lessThanOrEqualToForDurationInput,
    zerosNotAllowed,
};
