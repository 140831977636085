import { ButtonBase, Collapse } from "@material-ui/core";
import { SwitchBaseProps } from "@material-ui/core/internal/SwitchBase";
import clsx from "clsx";
import { LynxCheckBox } from "components/LynxComponents/LynxCheckBox/LynxCheckBox";
import { LynxSwitch } from "components/LynxComponents/LynxSwitch/LynxSwitch";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { LynxIcon } from "icons/LynxIcon";
import { observer } from "mobx-react";
import { GroupPermissionInfo, PermissionOverrideType } from "models/userManagement/userManagementModels";
import { useState } from "react";
import { useStore } from "store/StoreConfigs";
import { PermissionSectionProps } from "./PermissionSectionProps";
import { permissionSectionStyles } from "./PermissionSectionStyles";

export const PermissionSection = observer((props: PermissionSectionProps) => {
    const classes = permissionSectionStyles();
    const [expanded, setExpanded] = useState(false);

    const { groupStore } = useStore();

    const isChecked = (x: GroupPermissionInfo) => {
        if (x.overrideType === PermissionOverrideType.CheckAndIgnoreRole) {
            return true;
        } else if (x.overrideType === PermissionOverrideType.UncheckAndIgnoreRole) {
            return false;
        } else {
            return x.includedInRole;
        }
    };

    const permissions = (
        props.operationType === "add" ? groupStore.addGroupModel : groupStore.editGroupModel
    ).permissions.filter((x) => x.section === props.sectionName);
    const checked = permissions.some((x) => isChecked(x));

    const headerClassName = clsx({
        [classes.header]: true,
        [classes.headerExpanded]: expanded,
        [classes.headerTouchedExpanded]: expanded && checked,
        [classes.headerTouched]: checked,
        [classes.headerTouchedDisabled]: checked && props.disabled,
    });

    const toggleExpanded = () => setExpanded((prev) => !prev);

    const handleCheckboxToggle: SwitchBaseProps["onChange"] = (e, checked) => {
        permissions.forEach((x) => {
            x.overrideType = checked
                ? PermissionOverrideType.CheckAndIgnoreRole
                : PermissionOverrideType.UncheckAndIgnoreRole;
        });
    };

    const handleSwitchToggle = (x: GroupPermissionInfo, checked: boolean) => {
        x.overrideType = checked
            ? PermissionOverrideType.CheckAndIgnoreRole
            : PermissionOverrideType.UncheckAndIgnoreRole;
    };

    // const getOverrideName = (x: PermissionOverrideType) => {
    //     if (x === PermissionOverrideType.None) {
    //         return "Managed through role";
    //     } else if (x === PermissionOverrideType.CheckAndIgnoreRole) {
    //         return "Allow and ignore role";
    //     } else {
    //         return "Deny and ignore role";
    //     }
    // };

    // const roleSelected = !!(props.operationType === "add" ? groupStore.addGroupModel : groupStore.editGroupModel)
    //     .roleId;

    return (
        <div className={props.className}>
            <div className={headerClassName}>
                <LynxCheckBox
                    indeterminate={permissions.some((x) => isChecked(x)) && permissions.some((x) => !isChecked(x))}
                    checked={permissions.every((x) => isChecked(x))}
                    disabled={props.disabled}
                    onChange={handleCheckboxToggle}
                />
                <ButtonBase className={classes.headerLabel} onClick={toggleExpanded}>
                    <LynxTypography variant="body-medium"> {props.sectionName}</LynxTypography>
                </ButtonBase>
                <ButtonBase className={classes.headerButton} onClick={toggleExpanded}>
                    {expanded ? <LynxIcon name="minusSmall" /> : <LynxIcon name="plusSmall" />}
                </ButtonBase>
            </div>
            <Collapse in={expanded}>
                <div className={classes.body}>
                    {permissions.map((x) => {
                        return (
                            <div key={x.id} className={classes.permission}>
                                <div>
                                    <LynxTypography className={classes.permissionName}>{x.name}</LynxTypography>
                                    <LynxTypography className={classes.permissionDescription} variant="body-s">
                                        {x.description}
                                    </LynxTypography>
                                </div>
                                <LynxSwitch
                                    className={classes.permissionSwitch}
                                    disabled={props.disabled}
                                    checked={isChecked(x)}
                                    onChange={(e, checked) => handleSwitchToggle(x, checked)}
                                />
                                {/* Hide override type dropdown to simplify UI/UX */}
                                {/* {roleSelected && (
                                    <LynxSelect
                                        className={classes.overrideDropdown}
                                        value={x.overrideType}
                                        name="overrideType"
                                        onChange={(e) => (x.overrideType = e.target.value as PermissionOverrideType)}
                                        renderValue={(value) => getOverrideName(value as PermissionOverrideType)}
                                        disabled={props.disabled}
                                    >
                                        <MenuItem value={PermissionOverrideType.None}>Managed through role</MenuItem>
                                        <MenuItem value={PermissionOverrideType.CheckAndIgnoreRole}>
                                            Allow and ignore role
                                        </MenuItem>
                                        <MenuItem value={PermissionOverrideType.UncheckAndIgnoreRole}>
                                            Deny and ignore role
                                        </MenuItem>
                                    </LynxSelect>
                                )} */}
                            </div>
                        );
                    })}
                </div>
            </Collapse>
        </div>
    );
});

