import { useOktaAuth } from "@okta/okta-react";
import { services } from "api/serviceConfig";
import clsx from "clsx";
import { Copyright } from "components/Copyright/Copyright";
import { LynxButton } from "components/LynxComponents/LynxButton/LynxButton";
import { LynxCheckBox } from "components/LynxComponents/LynxCheckBox/LynxCheckBox";
import { LynxInput } from "components/LynxComponents/LynxInput/LynxInput";
import { LynxRedirectLink } from "components/LynxComponents/LynxRedirectLink/LynxRedirectLink";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { FullScreenLoadingIndicator } from "components/ReusableComponents/LoadingIndicator/FullScreenLoadingIndicator";
import { observer } from "mobx-react";
import React, { ChangeEvent, useState } from "react";
import { useNavigate } from "react-router";
import { useStore } from "store/StoreConfigs";
import { loginStyles } from "./LoginStyles";
import routes from "routes";

const loginFailedMsg = "Incorrect email or password";
const successfulLoginMsg = "Login successful";

// TODO: refactor using Formik or something similar
// TODO: add validation and error displaying
export const Login = observer(() => {
    const classes = loginStyles();

    const navigate = useNavigate();
    const { commonStore } = useStore();
    const { oktaAuth, authState } = useOktaAuth();

    const [email, setEmail] = useState(localStorage.getItem("email") || "");
    const [password, setPassword] = useState("");
    const [rememberMe, setRememberMe] = useState(!!localStorage.getItem("email"));
    const [loading, setLoading] = useState(false);

    const handleEmailChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
        setEmail(value);
    };

    const handlePasswordChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
        setPassword(value);
    };

    const handleRememberMeChange = (e: React.ChangeEvent<{}>, checked: boolean) => {
        setRememberMe(checked);
    };

    const onSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
        e.preventDefault();

        if (loading) {
            return;
        }

        setLoading(true);
        commonStore.setLoginError("");

        if (rememberMe) {
            localStorage.setItem("email", email);
        } else {
            localStorage.removeItem("email");
        }

        try {
            const response = await oktaAuth.signInWithCredentials({
                username: email,
                password,
            });

            await services.Users.logAuditEntry(email, successfulLoginMsg);

            // sessionToken is a one-use token, so make sure this is only called once
            // @ts-ignore
            await oktaAuth.signInWithRedirect({ sessionToken: response.sessionToken });
        } catch (error) {
            commonStore.setLoginError(loginFailedMsg);
            await services.Users.logAuditEntry(email, loginFailedMsg);
        } finally {
            localStorage.removeItem("userLoginError");
            setLoading(false);
        }
    };

    if (!authState) {
        return <FullScreenLoadingIndicator />;
    }

    if (authState.isAuthenticated) {
        navigate(routes.events);
        return null;
    }

    return (
        <>
            <form className={clsx(classes.loginForm, classes.marginVerticalAutoHorizontalZero)} onSubmit={onSubmit}>
                {commonStore.userLoginError && (
                    <LynxTypography color="critical500" className={classes.errorLabel}>
                        {commonStore.userLoginError}
                    </LynxTypography>
                )}
                <LynxInput
                    size="large"
                    name="email"
                    value={email}
                    label="Email"
                    onChange={handleEmailChange}
                    formControlClassName={classes.marginBottomMedium}
                />
                <LynxInput
                    size="large"
                    name="password"
                    value={password}
                    label="Password"
                    onChange={handlePasswordChange}
                    passwordInput
                    formControlClassName={classes.secondLoginInputMargin}
                />

                <LynxRedirectLink
                    to={routes.forgotPassword}
                    className={classes.forgotPasswordLink}
                    variant="body-medium"
                >
                    Forgot your Password?
                </LynxRedirectLink>

                <LynxButton
                    size="large"
                    loading={loading}
                    disabled={loading}
                    type="submit"
                    className={classes.signInButton}
                >
                    Sign In
                </LynxButton>
                <LynxCheckBox onChange={handleRememberMeChange} checked={rememberMe} label="Remember me" />
            </form>

            <Copyright />
        </>
    );
});

