import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const lynxSelectWithSearchStyles = makeStyles((theme: Theme) =>
    createStyles({
        autocomplete: {
            "&.MuiAutocomplete-root .MuiAutocomplete-endAdornment": {
                top: "unset",
                right: "2%",
                position: "absolute",
            },

            "& .MuiInputBase-root": {
                backgroundColor: theme.colors.neutrals.white,
            },

            minWidth: "15.25rem",
        },

        popper: {
            // hack so popper ignores space for error text, need to look how to improve error text display
            // so we don't need these hacks
            marginTop: "-0.75rem",
        },

        disabled: {
            "& .MuiInputBase-root": {
                backgroundColor: theme.colors.neutrals.neutral200,
            },
        },

        preventIconTransform: {
            "&.MuiAutocomplete-root .MuiAutocomplete-endAdornment .MuiIconButton-root": {
                "&.MuiAutocomplete-popupIndicator": {
                    transform: "unset",
                },
            },
        },

        clearIcon: {
            "&.MuiAutocomplete-root .MuiAutocomplete-endAdornment .MuiIconButton-root": {
                "&.MuiAutocomplete-clearIndicator": {
                    marginRight: "0.5rem",
                    padding: "0rem",
                    backgroundColor: theme.colors.neutrals.neutral200,

                    "&.MuiAutocomplete-clearIndicator .MuiIconButton-label": {
                        backgroundColor: theme.colors.neutrals.neutral200,
                        borderRadius: "1rem",
                    },
                },
            },
        },

        displayNone: {
            "&.MuiAutocomplete-root .MuiAutocomplete-endAdornment .MuiIconButton-root": {
                "&.MuiAutocomplete-clearIndicator": {
                    display: "none",
                },
            },
        },

        disablePopupIconInteractions: {
            "&.MuiAutocomplete-root .MuiAutocomplete-endAdornment .MuiIconButton-root.MuiAutocomplete-popupIndicator": {
                pointerEvents: "none",

                "&:hover": {
                    backgroundColor: "transparent",
                },
            },
        },

        option: {
            display: "flex",
            justifyContent: "space-between",
            borderBottom: `1px ${theme.colors.primary.blue100} solid`,
            padding: "0.5rem 1rem",

            "&:last-child": {
                border: "none",
            },
            "&:hover": {
                backgroundColor: theme.colors.primary.blue75,
            },
            "&.MuiAutocomplete-option[aria-selected=true]": {
                backgroundColor: "inherit",
            },
            "&.MuiAutocomplete-option[aria-selected=true]:hover": {
                backgroundColor: theme.colors.primary.blue75,
            },

            "&.MuiAutocomplete-option[data-focus=true]": {
                backgroundColor: theme.colors.primary.blue75,
            },
            "&.MuiAutocomplete-option[data-focus=true]:hover": {
                backgroundColor: theme.colors.primary.blue75,
            },
            "&.MuiAutocomplete-option:active": {
                backgroundColor: "inherit",
            },
        },

        loadingIndicatorContainer: {
            height: "3rem",
        },

        listBox: {
            border: `1px ${theme.colors.primary.blue100} solid`,
            borderRadius: theme.borderRadius,
        },

        input: {
            // super-hack for Autocomplete fields
            width: 0,
            minWidth: 30,
        },

        iconColor: {
            fill: theme.colors.neutrals.neutral300,
        },

        chips: {
            display: "flex",
            backgroundColor: theme.colors.primary.blue75,
            border: `1px solid ${theme.colors.primary.blue200}`,
            borderRadius: theme.borderRadius,
            marginRight: "0.5rem",
            marginBottom: "0.25rem",
            padding: "0.25rem 0.25rem 0.25rem 0.5rem",
        },

        removeIcon: {
            marginLeft: "1rem",
        },

        chipsDisplayName: {
            display: "flex",
            alignItems: "center",
        },

        assistiveTextHidden: {
            opacity: "0",
        },
    })
);

