import { SliderConditionChanged } from "chartjs-plugins/crop-temperature-range/interfaces/pluginDeclarations";
import { ChartOptions, Interaction, TooltipOptions, Tooltip } from "chart.js";
import { theme } from "styling/theme";
import { TooltipItemInfo } from "./TooltipItemInfo";
import { InteractionModeFunction, TooltipPositionerFunction, ChartType } from "chart.js/types/index.esm";
import { ExcursionSegment } from "models/thorEvents/eventModels";
import { AnnotationOptions } from "chartjs-plugin-annotation";
import { dateToFormat } from "helpers/dateFormattingHelper";
import { commonConstants } from "lynxConstants";

const chartJsHelper = require("chart.js/helpers");

declare module "chart.js" {
    interface InteractionModeMap {
        myCustomMode: InteractionModeFunction;
    }
    interface TooltipPositionerMap {
        myCustomPositioner: TooltipPositionerFunction<ChartType>;
    }
}

Tooltip.positioners.myCustomPositioner = function (elements, eventPosition) {
    return {
        x: eventPosition.x,
        y: eventPosition.y,
    };
};

Interaction.modes.myCustomMode = function (chart, e, options, useFinalPosition) {
    const items: TooltipItemInfo[] = [];
    const position = chartJsHelper.getRelativePosition(e, chart);
    const axis = "x";

    Interaction.evaluateInteractionItems(chart, axis, position, (element, datasetIndex, index) => {
        // Prevent duplicating datasets in tooltip
        const isDatasetAlreadyExist = items.some((x) => x.datasetIndex === datasetIndex);

        // Selecting points on X axis. For displaying each point on this AXIS - point hit radius set to 15
        if (element.inXRange(position.x, useFinalPosition) && !isDatasetAlreadyExist) {
            items.push({ element, datasetIndex, index });
        }

        // Selecting points on X axis and always display each dataset without setting point hit radius. (Nearest per dataset by X axis)
        // But this algorithm have bug, user cannot select (hover over) the last points on the chart
        // May be investigated and after fixing will work better than selecting with point hit.

        // const center = element.getCenterPoint(useFinalPosition);
        // const pointInArea = !!false || chart.isPointInArea(center);
        // const inRange = element.inXRange(position.x, useFinalPosition);

        // if (!pointInArea && !inRange) {
        //     return;
        // }

        // if (!isDatasetAlreadyExist) {
        //     items.push({ element, datasetIndex, index });
        // }
    });

    return items;
};

const tooltipOptions: Partial<TooltipOptions<"line">> = {
    enabled: false,
    external(context: any) {
        // Tooltip Element
        let tooltipEl = document.getElementById("chartjs-tooltip");
        // Create element on first render
        if (!tooltipEl) {
            tooltipEl = document.createElement("div");
            tooltipEl.id = "chartjs-tooltip";
            tooltipEl.innerHTML = "<div></div>";
            document.body.appendChild(tooltipEl);
        }

        // Hide if no tooltip
        context.tooltip.position = "nearest";
        const tooltipModel = context.tooltip;
        if (tooltipModel.opacity === 0) {
            tooltipEl.style.display = "none";
            return;
        }

        // Set caret Position
        tooltipEl.classList.remove("above", "below", "no-transform");
        if (tooltipModel.yAlign) {
            tooltipEl.classList.add(tooltipModel.yAlign);
        } else {
            tooltipEl.classList.add("no-transform");
        }

        function getBody(bodyItem: { lines: any }) {
            return bodyItem.lines;
        }

        // Set Text
        if (tooltipModel.body) {
            const titleLines = tooltipModel.title || [];
            const bodyLines = tooltipModel.body.map(getBody);
            const titleDiv = document.createElement("div");

            titleLines.forEach((title: string) => {
                const titleSpan = document.createElement("span");
                titleSpan.style.color = "rgba(51, 51, 51, 1)";

                const formattedData = dateToFormat(title, commonConstants.fullDateTimeFormat);

                const text = document.createTextNode(formattedData);

                const titleLine = document.createElement("div");
                titleLine.style.borderBottom = "1px solid #E8E8E8";
                titleLine.style.marginBottom = "10px";
                titleLine.style.marginTop = "10px";

                titleSpan.appendChild(text);
                titleDiv.appendChild(titleSpan);
                titleDiv.appendChild(titleLine);
            });

            const bodyDiv = document.createElement("div");
            bodyLines.forEach((body: string, i: number) => {
                const colors = tooltipModel.labelColors[i];
                const colorLine = document.createElement("span");
                colorLine.style.background = colors.backgroundColor;
                colorLine.style.borderColor = colors.borderColor;
                colorLine.style.borderWidth = "2px";
                colorLine.style.marginRight = "4px";
                colorLine.style.marginBottom = "4px";
                colorLine.style.height = "2px";
                colorLine.style.width = "20px";
                colorLine.style.display = "inline-block";

                const temperatureSpan = document.createElement("div");
                temperatureSpan.style.paddingLeft = "24px";
                temperatureSpan.style.fontWeight = "600";

                const monitorId = body.toString().split(":")[0].replace(":", " ");
                const temperature = body.toString().split(":")[1].replace(",", ".");

                const IdText = document.createTextNode(monitorId);
                const br = document.createElement("br");

                const DataText = document.createTextNode(parseFloat(temperature) + "°C");

                bodyDiv.appendChild(colorLine);
                bodyDiv.appendChild(br);
                bodyDiv.appendChild(IdText);
                temperatureSpan.appendChild(DataText);
                bodyDiv.appendChild(temperatureSpan);
                bodyDiv.appendChild(br);
            });

            const tableRoot = tooltipEl.querySelector("div");

            // Remove old children
            while (tableRoot!.firstChild) {
                tableRoot!.firstChild.remove();
            }

            tableRoot!.appendChild(titleDiv);
            tableRoot!.appendChild(bodyDiv);
        }

        const { offsetLeft: positionX, offsetTop: positionY } = context.chart.canvas;

        // Display, position, and set styles for font
        tooltipEl.style.display = "block";
        tooltipEl.style.left = positionX + tooltipModel.caretX + "px";
        tooltipEl.style.top = positionY + tooltipModel.caretY + "px";
        tooltipEl.style.font = tooltipModel.options.bodyFont.string;
        tooltipEl.style.padding = tooltipModel.options.padding + "px " + tooltipModel.options.padding + "px";
    },
};

const createExcursionBoxes = (excursionSegments: ExcursionSegment[]) => {
    const result: any = {};

    excursionSegments.map((x, index) => {
        return (result[`box${index + 1}`] = {
            type: "box",
            xMin: x.start.valueOf(),
            xMax: x.end.valueOf(),
            yMin: undefined,
            yMax: undefined,
            backgroundColor: "rgba(190, 68, 42, 0.25)",
            borderColor: "rgba(190, 68, 42, 0.25)",
            borderWidth: 1,
        });
    });

    return result;
};

const createTransportationRequirementsLines = (
    temperatureLowerLimit: number | null,
    temperatureUpperLimit: number | null
) => {
    const result: Record<string, AnnotationOptions> = {};

    if (temperatureLowerLimit !== null) {
        result.lowerLimit = {
            type: "line",
            yMin: temperatureLowerLimit,
            yMax: temperatureLowerLimit,
            borderColor: theme.colors.dataVisualization.orange,
            borderWidth: 2,
        };
    }

    if (temperatureUpperLimit !== null) {
        result.upperLimit = {
            type: "line",
            yMin: temperatureUpperLimit,
            yMax: temperatureUpperLimit,
            borderColor: theme.colors.dataVisualization.orange,
            borderWidth: 2,
        };
    }

    return result;
};

export const createOptions = (
    minDate: number,
    maxDate: number,
    minTemp: number,
    maxTemp: number,
    leftConditioning: number | null,
    rightConditioning: number | null,
    temperatureLowerLimit: number | null,
    temperatureUpperLimit: number | null,
    excursionSegments: ExcursionSegment[],
    maxNumberOfPoints: number,
    intervalOfLongestData: number,
    onSliderValueChangedHandler: SliderConditionChanged,
    timezone: string,
    isChartAnimationActive: false | undefined
) => {
    const slidersThrottle = (maxNumberOfPoints / 100) * intervalOfLongestData;
    const leftFixedBorderValue = minDate - slidersThrottle;
    const rightFixedBorderValue = maxDate + slidersThrottle;

    const leftSlider = leftConditioning ?? minDate;
    const rightSlider = rightConditioning ?? maxDate;
    const lowerLimit = temperatureLowerLimit === null ? +Infinity : temperatureLowerLimit;
    const upperLimit = temperatureUpperLimit === null ? -Infinity : temperatureUpperLimit;

    const options: ChartOptions<"line"> = {
        events: ["mouseup", "mousedown", "mousemove", "mouseout"],
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
            intersect: false,
            mode: "myCustomMode",
            axis: "x",
        },

        animation: isChartAnimationActive,
        plugins: {
            title: {
                display: false,
            },
            legend: {
                display: false,
            },
            conditioning: {
                minValue: leftFixedBorderValue,
                maxValue: rightFixedBorderValue,
                canvasOptions: {
                    modeDisabledBackgroundColor: "white",
                    modeEnabledBackgroundColor: "rgba(39, 10, 220, 0.2)",
                },
                leftSlider: {
                    modeDisabledColor: "lightgrey",
                    modeEnabledColor: "white",
                    sliderFixedBorderValue: leftFixedBorderValue,
                    sliderSlidingBorderValue: leftSlider,
                },
                rightSlider: {
                    modeDisabledColor: "lightgrey",
                    modeEnabledColor: "white",
                    sliderFixedBorderValue: rightFixedBorderValue,
                    sliderSlidingBorderValue: rightSlider,
                },
                onSliderConditionChanged: onSliderValueChangedHandler,
                disablePluginsWhenModeOn: ["zoom"],
            },
            tooltip: {
                ...tooltipOptions,
                position: "myCustomPositioner",
            },
            zoom: {
                zoom: {
                    onZoomComplete: ({ chart }) => {
                        // hack to remove tooltip when zooming with drag
                        chart.tooltip!.setActiveElements([], { x: 0, y: 0 });
                    },
                    wheel: {
                        enabled: true,
                    },
                    drag: {
                        enabled: true,
                    },
                    pinch: {
                        enabled: true,
                    },
                    mode: "xy",
                },
                pan: {
                    enabled: true,
                    mode: "xy",
                    modifierKey: "ctrl",
                },
                limits: {
                    x: {
                        min: leftFixedBorderValue,
                        max: rightFixedBorderValue,
                    },
                    y: {
                        min: Math.min(lowerLimit, minTemp) - 2,
                        max: Math.max(upperLimit, maxTemp) + 2,
                    },
                },
            },
            annotation: {
                annotations: {
                    ...createTransportationRequirementsLines(temperatureLowerLimit, temperatureUpperLimit),
                    ...createExcursionBoxes(excursionSegments),
                },
            },
        },
        elements: {
            point: {
                radius: 0,
            },
        },
        scales: {
            x: {
                type: "time",
                min: leftFixedBorderValue,
                max: rightFixedBorderValue,
                time: {
                    minUnit: "minute",
                    displayFormats: {
                        year: "dd MMM yyyy",
                        day: "dd MMM",
                        hour: "HH:mm",
                        minute: "HH:mm",
                    },
                },
                adapters: {
                    date: {
                        zone: timezone,
                        locale: "en",
                    },
                },
                ticks: {
                    autoSkip: true,
                    maxRotation: 0,
                    color: theme.colors.neutrals.neutral400,
                },
                grid: {
                    color: theme.colors.primary.blue100,
                    tickColor: "transparent",
                },
            },
            y: {
                suggestedMin: Math.min(lowerLimit, minTemp) - 1,
                suggestedMax: Math.max(upperLimit, maxTemp) + 1,
                type: "linear",
                afterTickToLabelConversion: (ctx) => {
                    if (temperatureLowerLimit !== null) {
                        ctx.ticks.push({ value: lowerLimit, label: `${temperatureLowerLimit}` });
                    }

                    if (temperatureUpperLimit !== null) {
                        ctx.ticks.push({ value: upperLimit, label: `${temperatureUpperLimit}` });
                    }
                },
                ticks: {
                    callback: function (value, index, ticks) {
                        const parsedValue = value as number;
                        const isCloseToLowerLimit = Math.abs(lowerLimit - parsedValue) <= 1;
                        const isCloseToUpperLimit = Math.abs(upperLimit - parsedValue) <= 1;

                        if (!isCloseToLowerLimit && !isCloseToUpperLimit) {
                            return parseFloat(parsedValue.toFixed(2));
                        }
                    },
                    color: theme.colors.neutrals.neutral400,
                },
                grid: {
                    tickColor: "transparent",
                    color: theme.colors.primary.blue100,
                },
            },
        },
    };

    return options;
};
