import { Grid, Tooltip } from "@material-ui/core";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import InfoSubheader from "components/ReusableComponents/InfoSubheader";
import { formatTemperatureRange } from "helpers/formatTemperatureRange";
import { LynxIcon } from "icons/LynxIcon";
import { observer } from "mobx-react";
import { ReactElement } from "react";
import { useStore } from "store/StoreConfigs";
import { eventTransportationInformationStyles } from "./EventTransportationInformationStyles";

interface TransportationInfoGridItem {
    label: string;
    value: string | null;
    icon?: ReactElement;
}

export const EventTransportationInformation = observer(() => {
    const classes = eventTransportationInformationStyles();
    const { thorEventViewStore } = useStore();
    const event = thorEventViewStore.eventDetails;

    const transportationRequirements = formatTemperatureRange(
        event.temperatureLowerLimit,
        event.temperatureUpperLimit,
        event.temperatureLowerLimitOperator,
        event.temperatureUpperLimitOperator,
        true
    );

    const transportationInfoIcon = <LynxIcon name="info" className={classes.icon} />;
    const tooltipContent =
        "Due to not having transportation requirements, product storage conditions were used for calculations.";

    // set second string in tuple to id attribute in case if value is empty - apply specific css rule
    const showValueOrEmpty = (item: string | null, id: number, label: string, icon?: ReactElement): any => {
        const value = item ? [item, "value"] : ["N/A", "N/A"];

        return (
            <Grid item key={id} lg={3} md={4} sm={4}>
                <LynxTypography variant="body-s" color="neutral400" className={classes.subtitle}>
                    {label}
                </LynxTypography>
                <LynxTypography id={`transportation_info.${id}.${value[1]}`} className={classes.valueText}>
                    {value[0]}
                    {!event.hasTransportationConditions && icon && (
                        <Tooltip
                            classes={{
                                tooltip: classes.transportationTooltip,
                                arrow: classes.transportationTooltipArrow,
                            }}
                            title={tooltipContent}
                            placement="bottom-start"
                            arrow
                        >
                            {icon}
                        </Tooltip>
                    )}
                </LynxTypography>
            </Grid>
        );
    };

    const firstSet: TransportationInfoGridItem[] = [
        {
            label: "Transportation Requirements",
            value: transportationRequirements,
            icon: transportationInfoIcon,
        },
        { label: "Lane ID", value: event.laneNumber },
        {
            label: "Lane Status",
            value: event.laneStatus,
        },
    ];

    const secondSet: TransportationInfoGridItem[] = [
        {
            label: "Origin Address",
            value: event.origin.addressLine1,
        },
        {
            label: "Origin Site ID",
            value: event.origin.code,
        },
        {
            label: "Origin Site Name",
            value: event.origin.name,
        },
    ];

    const thirdSet: TransportationInfoGridItem[] = [
        {
            label: "Destination Address",
            value: event.destination.addressLine1,
        },
        {
            label: "Destination Site ID",
            value: event.destination.code,
        },
        {
            label: "Destination Site Name",
            value: event.destination.name,
        },
    ];

    const fourthSet: TransportationInfoGridItem[] = [
        { label: "Shipper Type", value: event.shipperType },
        {
            label: "Mode of Transport",
            value: event.transportationModeName,
        },
        {
            label: "Transportation Service Provider",
            value: event.transportationServiceProviderName,
        },
        {
            label: "Logistics Service Provider",
            value: event.logisticsServiceProviderName,
        },
    ];

    const gridContainers = [firstSet, secondSet, thirdSet, fourthSet];

    return (
        <Grid container spacing={4} className={classes.container}>
            <Grid item xs={12} className={classes.paddingBottomZero}>
                <InfoSubheader text="transportation information" icon={<LynxIcon name="transportation" />} />
            </Grid>
            {gridContainers.map((itemSet, id) => (
                <Grid item container spacing={4} key={id} className={classes.contentStyles}>
                    {itemSet.map((item, id) => showValueOrEmpty(item.value, id, item.label, item.icon))}
                </Grid>
            ))}
        </Grid>
    );
});

