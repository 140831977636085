import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { HumanReadableAuditEntryPayloadModel } from "api/models/audit/auditApi";
import { auditEntryPayloadRendererStyles } from "./AuditEntryPayloadRendererStyles";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import clsx from "clsx";
import { AuditEntryPayloadCollapsableContent } from "./AuditEntryPayloadCollapsableContent";
import { AuditPayloadParser } from "./AuditPayloadParser";
import { Span } from "components/LynxComponents/LynxTypography/Span";

export interface AuditEntryPayloadRendererProps {
    payload: HumanReadableAuditEntryPayloadModel;
    forceExpand: boolean;
    timezone?: string;
}

const actionTexts = ["(added)", null, "(deleted)"];

export const AuditEntryPayloadRenderer = (props: AuditEntryPayloadRendererProps) => {
    const { payload } = props;
    const classes = auditEntryPayloadRendererStyles();

    const tableColumnHeader = (name?: string) => (
        <TableCell className={clsx(classes.tableCell, classes.tableHeaderCell)}>
            <Span variant="body-s" color="neutral400">
                {name}
            </Span>
        </TableCell>
    );

    const title = [payload.currentLevelName, actionTexts[payload.currentLevelAction!]].filter((x) => x).join(" ");

    const content = () => (
        <div className={classes.contentContainer}>
            {payload.changedFields.length > 0 && (
                <Table>
                    <TableHead>
                        <TableRow>
                            {tableColumnHeader("Field")}
                            {tableColumnHeader("Old Value")}
                            {tableColumnHeader("New Value")}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {payload.changedFields.map((x) => (
                            <TableRow key={x.column} className={classes.tableRow}>
                                <TableCell className={clsx(classes.tableCell)}>
                                    <LynxTypography>{x.columnDisplayName}</LynxTypography>
                                </TableCell>
                                <TableCell className={clsx(classes.tableCell)}>
                                    <LynxTypography>
                                        {AuditPayloadParser(x.oldValue, x.type, props.timezone)}
                                    </LynxTypography>
                                </TableCell>
                                <TableCell className={clsx(classes.tableCell)}>
                                    <LynxTypography>
                                        {AuditPayloadParser(x.newValue, x.type, props.timezone)}
                                    </LynxTypography>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            )}
            {payload.lowerLevels.map((x) => (
                <AuditEntryPayloadRenderer
                    forceExpand={props.forceExpand}
                    key={x.currentLevelName}
                    payload={x}
                    timezone={props.timezone}
                />
            ))}
        </div>
    );

    return (
        <div className={classes.root}>
            {payload.currentLevelName ? (
                <AuditEntryPayloadCollapsableContent forceExpand={props.forceExpand} title={title}>
                    {content()}
                </AuditEntryPayloadCollapsableContent>
            ) : (
                content()
            )}
        </div>
    );
};
