import { Grid } from "@material-ui/core";
import clsx from "clsx";
import { LynxButton } from "components/LynxComponents/LynxButton/LynxButton";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { PromptTooltip } from "components/ReusableComponents/PromptTooltip/PromptTooltip";
import VerticalLine from "components/ReusableComponents/VerticalLine";
import LynxDurationInputForm from "components/ReusableForms/LynxDurationInputForm";
import LynxInputForm from "components/ReusableForms/LynxInputForm";
import LabelWithRequiredSymbol from "components/ReusableForms/helper-components/LabelWithRequiredSymbol";
import { FieldArray, FormikProps, useFormikContext } from "formik";
import { LynxIcon } from "icons/LynxIcon";
import { promptTooltipMessages } from "lynxConstants";
import { observer } from "mobx-react";
import {
    RangeOperatorFrom,
    RangeOperatorTo,
    StabilityFormModel,
    TemperatureRangeModel,
} from "models/productAndStabilityForm/productAndStabilityFormModels";
import { ChangeEvent, useEffect, useState } from "react";
import uuid from "react-uuid";
import { useStore } from "store/StoreConfigs";
import { stabilityFormStyles } from "../StabilityFormStyles";
import {
    allCountriesAndRegions,
    handleChangeRangeOperatorFrom,
    handleChangeRangeOperatorTo,
} from "../helper-functions";
import { RegionSelectOption } from "./Props/RegionFieldsAreaProps";
import { TemperatureRangeFieldsAreaProps } from "./Props/TemperatureRangeFieldsAreaProps";
import RangeFieldsArea from "./RangeFieldsArea";
import RegionFieldsArea from "./RegionFieldsArea";

const TemperatureRangeFieldsArea = observer((props: TemperatureRangeFieldsAreaProps) => {
    const { rangeHelpers, rangeIndex } = { ...props };
    const formik = useFormikContext<StabilityFormModel>();
    const { customerDataStore } = useStore();
    const classes = stabilityFormStyles();
    const [regions, setRegions] = useState<RegionSelectOption[]>([]);
    const rangeRegions = formik.values.temperatureRanges[rangeIndex].rangeRegions;
    const atLeastOneFlowReferenced = rangeRegions.some((x) => x.flows.some((y) => y.isReferenced));
    const rangeReferenced = formik.values.temperatureRanges[rangeIndex].isReferenced;

    const getRangeErrorField = (
        fieldName: "lowerLimit" | "upperLimit",
        index: number,
        formik: FormikProps<StabilityFormModel>
    ) => {
        const range = formik.errors.temperatureRanges?.[index] as TemperatureRangeModel | undefined;

        return range?.[fieldName];
    };

    const defineSelectedRegions = () => {
        const regionsWithEmptyState = [
            { id: allCountriesAndRegions, name: allCountriesAndRegions },
            ...customerDataStore.regions,
        ];

        return regionsWithEmptyState.map((x) => {
            const region = rangeRegions.find((y) => x.id === y.regionId);

            if (region) {
                return { region: x, disabled: true };
            }

            return { region: x, disabled: false };
        });
    };

    const disableSelectedRegion = (
        e: ChangeEvent<{
            name?: string | undefined;
            value: unknown;
        }>,
        previousRegionId: string
    ) => {
        const regionId = e.target.value as string;
        const oldValue = regions.find((x) => x.region.id === previousRegionId);

        const newRegionOptions = regions.map((x) => {
            if (x.region.id === regionId) {
                return { ...x, disabled: true };
            }

            if (x.region.id === oldValue?.region.id) {
                return { ...x, disabled: false };
            }

            return x;
        });

        setRegions(newRegionOptions);
    };

    const enableRegionOption = (regionId: string) => {
        const newRegionOptions = regions.map((x) => {
            if (x.region.id === regionId) {
                return { ...x, disabled: false };
            }

            return x;
        });

        setRegions(newRegionOptions);
    };

    const setTemperatureRangeValues = () => {
        const rangeRegions =
            formik.values.temperatureRanges[rangeIndex].rangeRegions.length <= 0
                ? [
                      {
                          id: "",
                          key: uuid(),
                          regionId: "",
                          medicalInfo: "",
                          flows: [
                              {
                                  id: "",
                                  key: uuid(),
                                  name: "",
                                  steps: "",
                                  plannedDeduction: "",
                                  remainingStabilityBudget: "",
                                  references: "",
                              },
                          ],
                      },
                  ]
                : [];

        formik.setFieldValue(`temperatureRanges.${rangeIndex}.medicalInfo`, "");
        formik.setFieldValue(`temperatureRanges.${rangeIndex}.remainingStabilityBudget`, "");
        formik.setFieldValue(`temperatureRanges.${rangeIndex}.rangeRegions`, rangeRegions);
        setRegions(defineSelectedRegions());
    };

    const showPromptTooltipMessageForRange = () => {
        if (rangeReferenced) {
            return promptTooltipMessages.stabilityForm.rangeCannotBeDeletedBecauseReferenced;
        }

        if (atLeastOneFlowReferenced) {
            return promptTooltipMessages.stabilityForm.flowCannotBeDeletedBecauseReferenced;
        }

        return promptTooltipMessages.empty;
    };

    useEffect(() => {
        setRegions(defineSelectedRegions());
    }, [formik.initialValues]);

    return (
        <Grid container className={classes.rangeSection} item>
            <Grid container item justifyContent="space-between" className={classes.marginBottomLarge}>
                <Grid item lg={5} md={7} sm={8}>
                    <LynxTypography variant="body-s" color="neutral400">
                        {<LabelWithRequiredSymbol label="Temperature Range" />}
                    </LynxTypography>
                    <RangeFieldsArea
                        operatorFrom={{
                            selectProps: {
                                name: `temperatureRanges.${rangeIndex}.rangeOperatorFrom`,
                                onChange: (e: ChangeEvent<{ name?: string | undefined; value: unknown }>) =>
                                    handleChangeRangeOperatorFrom(
                                        e,
                                        formik,
                                        `temperatureRanges.${rangeIndex}.rangeOperatorFrom`,
                                        `temperatureRanges.${rangeIndex}.lowerLimit`
                                    ),
                                className: classes.minWidthOperator,
                            },
                            infinityDisabled:
                                formik.values.temperatureRanges[rangeIndex].rangeOperatorTo ===
                                RangeOperatorTo.INFINITY,
                            assistiveText: {
                                content: "Min Temp",
                            },
                        }}
                        from={{
                            inputProps: {
                                name: `temperatureRanges.${rangeIndex}.lowerLimit`,
                                placeholder: "Low",
                                disabled:
                                    formik.values.temperatureRanges[rangeIndex].rangeOperatorFrom ===
                                    RangeOperatorFrom.INFINITY,
                            },
                            errorText: {
                                content: getRangeErrorField("lowerLimit", rangeIndex, formik),
                            },
                        }}
                        operatorTo={{
                            selectProps: {
                                name: `temperatureRanges.${rangeIndex}.rangeOperatorTo`,
                                onChange: (e: ChangeEvent<{ name?: string | undefined; value: unknown }>) =>
                                    handleChangeRangeOperatorTo(
                                        e,
                                        formik,
                                        `temperatureRanges.${rangeIndex}.rangeOperatorTo`,
                                        `temperatureRanges.${rangeIndex}.upperLimit`
                                    ),
                                className: classes.minWidthOperator,
                            },
                            infinityDisabled:
                                formik.values.temperatureRanges[rangeIndex].rangeOperatorFrom ===
                                RangeOperatorFrom.INFINITY,
                            assistiveText: {
                                content: "Max Temp",
                            },
                        }}
                        to={{
                            inputProps: {
                                name: `temperatureRanges.${rangeIndex}.upperLimit`,
                                placeholder: "High",
                                disabled:
                                    formik.values.temperatureRanges[rangeIndex].rangeOperatorTo ===
                                    RangeOperatorTo.INFINITY,
                            },
                            errorText: {
                                content: getRangeErrorField("upperLimit", rangeIndex, formik),
                            },
                        }}
                    />
                </Grid>
                <Grid
                    item
                    xs={4}
                    justifyContent="flex-end"
                    className={clsx(classes.heightFitContent, classes.displayFlex)}
                >
                    <PromptTooltip
                        placement="top"
                        title={
                            atLeastOneFlowReferenced
                                ? promptTooltipMessages.stabilityForm.flowCannotBeDeletedBecauseReferenced
                                : promptTooltipMessages.empty
                        }
                    >
                        <LynxButton
                            size="medium"
                            variant="tertiary"
                            onClick={() => setTemperatureRangeValues()}
                            disabled={atLeastOneFlowReferenced}
                            leftIcon={<LynxIcon name={rangeRegions.length <= 0 ? "plusSmall" : "minusSmall"} />}
                        >
                            {rangeRegions.length <= 0 ? "Add" : "Remove"} Flow(s)
                        </LynxButton>
                    </PromptTooltip>
                    <VerticalLine />
                    <PromptTooltip placement="top" title={showPromptTooltipMessageForRange()}>
                        <LynxButton
                            size="medium"
                            variant="icon"
                            disabled={
                                formik.values.temperatureRanges.length <= 1 ||
                                atLeastOneFlowReferenced ||
                                rangeReferenced
                            }
                            onClick={() => {
                                if (formik.values.temperatureRanges.length <= 1) {
                                    return;
                                }
                                rangeHelpers.remove(rangeIndex);
                            }}
                        >
                            <LynxIcon name="trash" />
                        </LynxButton>
                    </PromptTooltip>
                </Grid>
            </Grid>
            {rangeRegions.length <= 0 ? (
                <Grid container item className={classes.inputsGapMedium}>
                    <Grid item xs={2}>
                        <LynxDurationInputForm
                            name={`temperatureRanges.${rangeIndex}.totalStabilityBudget`}
                            label="Total Stability Budget"
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <LynxDurationInputForm
                            name={`temperatureRanges.${rangeIndex}.medicalInfo`}
                            label="Medical Info"
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <LynxDurationInputForm
                            name={`temperatureRanges.${rangeIndex}.remainingStabilityBudget`}
                            label={<LabelWithRequiredSymbol label="Remaining Stability Budget" />}
                        />
                    </Grid>
                    <Grid item xs>
                        <LynxInputForm
                            multiline
                            name={`temperatureRanges.${rangeIndex}.references`}
                            placeholder="Enter References"
                            label="References"
                        />
                    </Grid>
                </Grid>
            ) : (
                <>
                    <Grid
                        container
                        item
                        className={clsx(
                            classes.inputsGapMedium,
                            classes.dividerBottom,
                            classes.marginBottomSmall,
                            classes.paddingBottomSmall
                        )}
                    >
                        <Grid item lg={2} md={3} sm={3}>
                            <LynxDurationInputForm
                                name={`temperatureRanges.${rangeIndex}.totalStabilityBudget`}
                                label="Total Stability Budget"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <LynxInputForm
                                multiline
                                name={`temperatureRanges.${rangeIndex}.references`}
                                placeholder="Enter References"
                                label="References"
                            />
                        </Grid>
                    </Grid>

                    <LynxTypography
                        variant="h3"
                        color="neutral400"
                        className={clsx(classes.marginTopMedium, classes.marginBottomMedium)}
                    >
                        Countries & regions {`(${rangeRegions.length})`}
                    </LynxTypography>

                    <FieldArray
                        name={`temperatureRanges.${rangeIndex}.rangeRegions`}
                        render={(regionHelpers) => (
                            <Grid container item>
                                {rangeRegions.map((rangeRegion, regionIndex) => (
                                    <RegionFieldsArea
                                        key={rangeRegion.id ? rangeRegion.id : rangeRegion.key}
                                        rangeIndex={rangeIndex}
                                        regionIndex={regionIndex}
                                        regionHelpers={regionHelpers}
                                        regions={regions}
                                        disableSelectedRegion={disableSelectedRegion}
                                        enableRegionOption={enableRegionOption}
                                    />
                                ))}

                                <Grid item className={classes.marginBottomMedium}>
                                    <LynxButton
                                        variant="secondary"
                                        onClick={() =>
                                            regionHelpers.push({
                                                id: "",
                                                key: uuid(),
                                                regionId: "",
                                                medicalInfo: "",
                                                flows: [
                                                    {
                                                        id: "",
                                                        key: uuid(),
                                                        name: "",
                                                        steps: "",
                                                        plannedDeduction: "",
                                                        remainingStabilityBudget: "",
                                                        references: "",
                                                    },
                                                ],
                                            })
                                        }
                                        leftIcon={<LynxIcon name="plusSmall" />}
                                    >
                                        Add Region
                                    </LynxButton>
                                </Grid>
                            </Grid>
                        )}
                    />
                </>
            )}
        </Grid>
    );
});

export default TemperatureRangeFieldsArea;

