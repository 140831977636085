import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const deviceStyles = makeStyles((theme: Theme) =>
    createStyles({
        main: {
            padding: "30px",
            boxShadow: theme.mainShadow,
            margin: "50px 50px 50px 0px",
            display: "flex",
        },
        conditioned: {
            color: theme.colors.dataVisualization.midnight,
            display: "flex",
            margin: "12px 0px 10px",
        },
        noChangesText: {
            color: theme.colors.dataVisualization.midnight,
            marginTop: "3px",
        },
        arrowIcon: {
            fill: theme.colors.dataVisualization.midnight,
            width: "1.25rem",
            margin: "2px 5px 0px 0px",
        },
    })
);

