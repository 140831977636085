import { setLocale } from "yup";

export const setDefaultMessagesForValidation = () =>
    setLocale({
        string: {
            max: (params) => `Max ${params.max} characters allowed`,
            min: (params) => `Min ${params.min} characters allowed`,
            matches: "Invalid value",
            email: "Not a valid email address format",
        },
        mixed: {
            required: "Required",
        },
        number: {
            min: (params) => `Must be greater than or equal to ${params.min}`,
            max: (params) => `Must be less than or equal to ${params.max}`,
            moreThan: (params) => `Must be greater than ${params.more}`,
            lessThan: (params) => `Must be less than ${params.less}`,
            integer: "Must be an integer",
        },
    });
