import { Grid, InputAdornment, MenuItem } from "@material-ui/core";
import clsx from "clsx";
import { LynxIcon } from "icons/LynxIcon";
import { observer } from "mobx-react";
import { ChangeEvent, KeyboardEvent } from "react";
import { LynxInput } from "../LynxInput/LynxInput";
import { LynxSelect } from "../LynxSelect/LynxSelect";
import { lynxSearchStyles } from "./LynxSearchStyles";
import { LynxSearchProps } from "./SearchProps";

export default observer(function LynxSearch({
    options,
    searchParameter,
    searchValue,
    setSearchValue,
    setSearchParameter,
    request,
}: LynxSearchProps) {
    const classes = lynxSearchStyles();

    const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
        const trimmed = e.target.value.trimLeft().replace(/\s{2,}/g, " ");
        setSearchValue(trimmed);
    };

    const handleSearch = () => {
        request();
    };

    const handleSearchByEnter = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
            handleSearch();
        }
    };

    const handleChangeSearchParameter = (event: ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
        setSearchParameter(event.target.value as string);
    };

    const handleClearSearchValue = () => {
        setSearchValue("");
        request();
    };

    const getSearchPlaceholder = (label: string) => {
        return "Search by " + label;
    };

    return (
        <Grid container>
            <Grid item>
                <LynxSelect
                    placeholder="Select category"
                    value={searchParameter}
                    className={classes.select}
                    onChange={handleChangeSearchParameter}
                >
                    {options.map((x) => (
                        <MenuItem key={x.parameter} value={x.parameter}>
                            {x.label}
                        </MenuItem>
                    ))}
                </LynxSelect>
            </Grid>
            <Grid item>
                <LynxInput
                    className={classes.searchInput}
                    placeholder={getSearchPlaceholder(options.find((x) => x.parameter === searchParameter)!.label)}
                    value={searchValue}
                    onChange={handleChangeInput}
                    onKeyDown={handleSearchByEnter}
                    endAdornment={
                        <InputAdornment position="end" className={classes.union}>
                            {searchValue.length > 0 && (
                                <LynxIcon
                                    name="crossSmall"
                                    className={classes.crossIcon}
                                    onClick={handleClearSearchValue}
                                />
                            )}
                            <LynxIcon name="search" onClick={handleSearch} className={clsx(classes.searchIcon)} />
                        </InputAdornment>
                    }
                />
            </Grid>
        </Grid>
    );
});

